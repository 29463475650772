import React from "react";
import fbLogo from "../../../assets/fbLogo3.png";

const Welcome = () => {
  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Welcome
      </h2>
      <div className="text-gray-700 flex flex-row text-base font-gillSans">
        <div>
          <p className="mt-10 lg:text-lg">
            Welcome to the Feeling Better ASD Pain Management Programme.
          </p>
          <p className="mt-10 lg:text-lg">
            Feeling Better ASD was developed for children with a diagnosis of
            autism spectrum disorder/ Intellectual Disability who experience
            pain.
          </p>
          <p className="mt-10 lg:text-lg">
            This programme provides education for autistic children by teaching
            the skill of communicating pain and also learning skills and
            techniques such as relaxation, activity pacing, and distraction to
            help children cope with pain.
          </p>
        </div>
        <div>
          <img src={fbLogo} alt="Feeling Better" className=" w-4/5" />
        </div>
      </div>
    </div>
  );
};

export default Welcome;
