import React, { useState } from "react";
import cptCharlie from "../../assets/cptCharlie.png";

const TopMission = ({ onNext }) => {
  const [ready, setReady] = useState(false);

  const handleStart = () => {
    setReady(true);
    onNext();
  };

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Top Mission
      </h2>
      <div className="text-gray-700 flex flex-row justify-around text-lg -mt-6 font-gillSans">
        <div>
          <img src={cptCharlie} className="w-9/12" alt="cptCharlie" />
        </div>
        <div>
          <ol className="lg:mt-10 md:mt-5 ">
            <li className="lg:mt-6 md:mt-1">
              Captain Charlie has set a mission for you.
            </li>
            <li className="lg:mt-6 md:mt-1">
              Annie, one of Captain Charlie's friends has some body parts that
              hurt, and Captain Charlie wants you to help Annie show where it
              hurts.
            </li>
            <li className="lg:mt-6 md:mt-1">You have a total of 3 tasks</li>
            <li className="lg:mt-6 md:mt-1">Are you ready for this mission?</li>
          </ol>
          {ready ? (
            <p>Started!</p>
          ) : (
            <button
              className=" lg:mt-5 md:mt-2 px-8 py-2 rounded-md bg-green-500 text-white font-bold transition duration-200 hover:bg-[#ebdbc4] hover:text-[#374151] border-2 border-transparent hover:border-[#374151]"
              onClick={handleStart}
            >
              Yes!
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopMission;
