import React, { useState } from "react";
import axios from "axios";
import painScale0 from "../../assets/pain scale/0.png";
import painScale2 from "../../assets/pain scale/2.png";
import painScale4 from "../../assets/pain scale/4.png";
import painScale6 from "../../assets/pain scale/6.png";
import painScale8 from "../../assets/pain scale/8.png";
import painScale10 from "../../assets/pain scale/10.png";

const PiratePainQuiz = ({ carrier }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [feedback, setFeedback] = useState("");
  const [feedbackClass, setFeedbackClass] = useState("");
  const [clickedIndex, setClickedIndex] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [showSecondQuestion, setShowSecondQuestion] = useState(false);

  const painScales = [
    painScale0,
    painScale2,
    painScale4,
    painScale6,
    painScale8,
    painScale10,
  ];

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    if (option === 2) {
      if (carrier && carrier.firstName) {
        setFeedback(
          `Correct answer, Excellent Job Striker ${carrier.firstName}`
        );
      } else {
        setFeedback("Correct answer, Excellent Job Striker!");
      }
      setFeedbackClass("zoomer");

      // Show the second question after 2 seconds
      setTimeout(() => {
        setShowSecondQuestion(true);
      }, 2000);
    } else {
      setFeedback("Incorrect answer, Let's Try Again.");
      setFeedbackClass("shake");
    }
  };

  const handleImageClick = async (index) => {
    if (index >= 0 && index <= 5) {
      setClickedIndex(index);
    } else {
      console.error(
        "Index out of range. Please provide an index between 0 and 5."
      );
      return;
    }

    let userId = carrier.userId;
    // Hit the API to save activity details
    try {
      const payload = [
        {
          userId: userId, // Assuming userId is part of carrier
          missionId: "M3", // Example mission ID
          pageNum: 26, // Example page number
          activityId: "M3PainQuiz", // Example activity ID
          activityValue: JSON.stringify({ selectedOption: index }), // Save selected option
        },
      ];
      const response = await axios.post(
        "https://api.feelingbetterasd.com/mission/activity/saveMissionActivityDetails",
        payload
      );
      console.log("Response:", response.data);
      setSubmitted(true);
    } catch (error) {
      console.error("Error saving mission activity:", error);
    }
  };

  return (
    <>
      <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
        <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
          A Pirate's Pain Quiz
        </h2>
        <div className="text-gray-700 text-base font-gillSans">
          {!showSecondQuestion ? (
            // Render Question 1
            <>
              <p className="mt-5 underline lg:text-lg text-center">
                What is a pain scale used for?
              </p>
              <ol className="list-decimal flex justify-center flex-col items-center">
                <li
                  className="mt-5 cursor-pointer hover:scale-110 hover:text-[#F79D84] transform transition duration-300 ease-in-out"
                  onClick={() => handleOptionClick(1)}
                >
                  Measuring how tall you are
                </li>
                <li
                  className="mt-5 cursor-pointer hover:scale-110 hover:text-[#F79D84] transform transition duration-300 ease-in-out"
                  onClick={() => handleOptionClick(2)}
                >
                  To tell your mum/dad or teacher how much something hurts
                </li>
                <li
                  className="mt-5 cursor-pointer hover:scale-110 hover:text-[#F79D84] transform transition duration-300 ease-in-out"
                  onClick={() => handleOptionClick(3)}
                >
                  Counting how many toys I have
                </li>
              </ol>
              {selectedOption && (
                <p className={`mt-10 text-center lg:text-lg ${feedbackClass}`}>
                  {feedback}
                </p>
              )}
              {submitted && (
                <div className="mt-5 text-center text-green-600 font-bold">
                  Submitted successfully!
                </div>
              )}
            </>
          ) : (
            // Render Question 2 if the first question is answered correctly
            <div className="text-gray-700 text-base font-gillSans mt-10 fadeIn">
              <ol className="">
                <li className="mt-1">
                  Can you guess which faces show the{" "}
                  <span className="font-semibold">most</span> pain?
                </li>
                <li className="mt-4">Touch the face-</li>
                <div className="flex mt-10 justify-around items-center gap-5 max-w-3xl">
                  {painScales.map((src, index) => (
                    <div className="flex items-center flex-col" key={index}>
                      <img
                        src={src}
                        alt={`pain-scale-${index}`}
                        className="hover:scale-110 transform transition duration-300 ease-in-out cursor-pointer"
                        onClick={() => handleImageClick(index)}
                      />
                    </div>
                  ))}
                </div>
                {clickedIndex === 5 && (
                  <div className="bounceIn text-center mt-4">
                    <ol>
                      <li>Well done!</li>
                      <li>That's correct! The red face shows the most pain!</li>
                    </ol>
                  </div>
                )}
                {clickedIndex !== 5 && clickedIndex !== null && (
                  <div className="shake text-center mt-4">
                    <ol>
                      <li>Incorrect answer</li>
                      <li>Let's try again!</li>
                    </ol>
                  </div>
                )}
              </ol>
              {submitted && (
                <div className="mt-5 text-center">Submitted successfully!</div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PiratePainQuiz;
