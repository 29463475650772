import React from "react";
import cptCharlie from "../../../assets/cptCharlie.png";

const CptCharlie = () => {
  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Captain Charlie
      </h2>
      <div className="text-gray-700 flex flex-row text-base font-gillSans mx-5">
        <div>
          <p className="mt-5 lg:text-lg">
            Captain Charlie is the main character in this programme.
          </p>
          <p className="mt-5 lg:text-lg">
            Captain Charlie will encourage your child to learn about pain and
            different coping skills.
          </p>
          <p className="mt-5 lg:text-lg">
            It is important that your child finishes each mission.
          </p>
          <p className="mt-5 lg:text-lg">
            At the end of each mission, there is a quiz that your child must
            complete. This quiz asks your child what they have learnt.
          </p>
        </div>
        <div>
          <img src={cptCharlie} alt="Captain Charlie" className=" w-4/5" />
        </div>
      </div>
    </div>
  );
};

export default CptCharlie;
