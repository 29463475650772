import React from "react";
// import placeHolder from "../../../assets/placeHolder160x160.png";
import rachel from "../../../assets/Team/Rachel.jpg";
import helena from "../../../assets/Team/helenalydon.jpg.png";
import brian from "../../../assets/Team/Brian.jpg";

const Team = () => {
  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-44">
      <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        The Team
      </h2>
      <div className="text-gray-700 flex flex-row text-base font-gillSans mx-10 -mt-4">
        <div className="flex flex-row">
          <ul>
            <li className="mt-5 text-base">
              <div className="flex flex-row gap-2">
                <img src={rachel} alt="Feeling Better" className=" w-24" />{" "}
                <p>
                  Rachel is a PhD candidate in Applied Behaviour Analysis in the
                  School of Psychology at the University of Galway. Rachel
                  developed this online version of the Feeling Better ASD pain
                  management programme for autistic children aged 5-17 years of
                  age who experience recuurent or chronic pain as part of her
                  PhD. Rachel is interested in the use of technology-based
                  interventions to increase the communication and understanding
                  of pain for autistic children who experience pain and have
                  difficulty communicating pain. She is under the supervision of
                  Dr. Helena Lydon and Prof. Brian McGuire.
                </p>
              </div>
            </li>
            <li className="mt-5 text-base">
              <div className="flex flex-row gap-2">
                <img src={helena} alt="Feeling Better" className=" w-24" />
                <p>
                  Dr. Helena Lydon is a Chartered Behavioural Psychologist. She
                  has worked for many years as a Behaviour Therapist and Senior
                  Behaviour Specialist with children and adults in Intellectual
                  and Developmental Disability Services. Her clinical experience
                  is in the treatment of challenging behaviour and individuals
                  presenting with a dual diagnosis across educational,
                  residential and respite settings. Dr. Lydon's research
                  interests include the application of behaviour analysis to
                  health related issues (e.g. feeding, sleep, communication of
                  pain, equal access to healthcare).
                </p>
              </div>
            </li>
            <li className="mt-5 text-base">
              <div className="flex flex-row gap-2">
                <img src={brian} alt="Feeling Better" className=" w-24" />
                <p>
                  Prof. Brian McGuire is HRB Research Leader in Population
                  Health and Joint Director of the Centre for Pain Research ay
                  NUI Galway. Prof. McGuire's research interests lie in clinical
                  health psychology and behavioural medicine, especially pain
                  management and psychological treatment in chronic health
                  problems. Brian is also a practising Clinical Psychologist.
                  Prof. McGuire set up the manualised version of the Feeling
                  Better for people who have intellectual disabilities and
                  chronic pain.
                </p>
              </div>
            </li>
          </ul>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default Team;
