import React, { useState } from "react";

const PQIntro = ({ onNext, carrier }) => {
  const [ready, setReady] = useState(false);

  const handleStart = () => {
    setReady(true);
    onNext();
  };

  return (
    <div className=" w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Pirate Quiz
      </h2>
      <div className="text-gray-700 text-center  font-gillSans">
        <div>
          <p className=" mt-10 mb-10 lg:text-base md:text-base">
            Deckhand {carrier?.firstName}, a special pirate quiz awaits you.
          </p>
          <p className=" mt-10 mb-10 lg:text-base md:text-base">
            Captain Charlie wants to know more about body parts that hurt you.
          </p>
          <p className=" mt-10 mb-10 lg:text-base md:text-base">
            Are you ready?
          </p>
          {ready ? (
            <p>Started!</p>
          ) : (
            <button
              className="px-8 py-2 rounded-md bg-green-500 text-white font-bold transition duration-200 hover:bg-[#ebdbc4] hover:text-[#374151] border-2 border-transparent hover:border-black"
              onClick={handleStart}
            >
              Yes, let's go!
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PQIntro;
