import React from "react";
import commPainMaster from "../../assets/Badges/commMaster.png";

const Badge3 = () => {
  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-20">
      <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Your Special Badge
      </h2>
      <div className="text-gray-700 text-base font-gillSans">
        <ol className="mt-10 ">
          <li className="mt-6">
            Congratulations, Captain Charlie has awarded you a special badge
            called{" "}
            <span className="font-semibold">'Communicating Pain Champion'</span>{" "}
            for learning 3 ways to communicate pain.
            <ol className=" list-decimal ml-4">
              <li>Pointing to the body part that hurts.</li>
              <li>Pointing to the body map.</li>
              <li>Using the pain scale and pointing to the different faces.</li>
            </ol>
          </li>
          <div className="  flex mt-4 justify-center pr-6 gap-5 lg:pr-8">
            <img src={commPainMaster} alt="badge" className="w-56" />
          </div>
        </ol>
      </div>
    </div>
  );
};

export default Badge3;
