import React from 'react'

const mission11 = () => {
  return (
    <div>
      <p>Mission 11</p>
    </div>
  )
}

export default mission11
