import React, { useState } from "react";
import BodyMap from "../BodyMapNew";
import Correct from "./Correct";
import Incorrect from "./Incorrect";
import axios from "axios";

const Section4 = (props) => {
  const [answer, setAnswer] = useState(null);

  // Destructure userId from the props.carrier object
  const userId = props.carrier.userId;
  const missionId = "M1";
  const pageNum = 15;
  const activityId = "M1PirateQuiz";

  // Function to save the answer
  const saveAnswer = async (activityValue) => {
    const payload = [
      {
        userId: userId,
        missionId: missionId,
        pageNum: pageNum,
        activityId: activityId,
        activityValue: activityValue,
      },
    ];
    try {
      const response = await axios.post(
        "https://api.feelingbetterasd.com/mission/activity/saveMissionActivityDetails",
        payload
      );
      console.log("Response:", response.data);
      props.onCoinUpdate(3);
    } catch (error) {
      console.error("Error saving answer:", error);
    }
  };

  // Event handler for area clicks
  const handleAreaClick = async (areaTitle) => {
    if (areaTitle === "Stomach") {
      setAnswer("correct");
      sessionStorage.setItem("section4QuizCompleted", "true");
      await saveAnswer("correct");
    } else {
      setAnswer("incorrect");
      // await saveAnswer("incorrect");
    }
  };

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Pirate Quiz!
      </h2>
      <div className="text-gray-700 text-base flex justify-evenly font-gillSans">
        {answer === null ? (
          <ol className=" mt-5">
            <li>{props.carrier?.firstName} what body part hurt Annie?</li>
            <li className="mt-4">Touch the body part on the picture</li>
          </ol>
        ) : answer === "correct" ? (
          <Correct />
        ) : (
          <Incorrect handlePrevious={props.handlePrevious} />
        )}
        <BodyMap onAreaClick={handleAreaClick} />
      </div>
    </div>
  );
};

export default Section4;
