import React, { useState } from "react";
import cptCharlie from "../../assets/cptCharlie.png";

const Adventure = ({ onNext }) => {
  const [ready, setReady] = useState(false);

  const handleStart = () => {
    setReady(true);
    onNext();
  };

  return (
    <div className=" w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        A Pirates Adventure
      </h2>
      <div className="text-gray-700 flex flex-row items-start font-gillSans">
        <div className=" lg:w-96 md:w-64">
          <img src={cptCharlie} alt="cptCharlie" />
        </div>
        <div>
          <ul>
            <li className=" mt-10 lg:text-xl md:text-xl">
              You have a special adventure that you must complete.
            </li>
            <li className="mt-2 lg:text-lg md:text-lg">You will hear two stories:</li>
            <ol className="list-decimal mt-1">
              <li>Annie will talk about her pain</li>
              <li>Morgan will talk about his pain</li>
            </ol>
            <li className="lg:text-lg md:text-lg">Captain Charlie has asked Annie 'How are you feeling?'</li>
            <li className="lg:text-lg md:text-lg">
              Annie needs your help to pick the face that shows how much pain
              she is in.
            </li>
          </ul>
          {ready ? (
            <p>Started!</p>
          ) : (
            <button
              className="px-8 py-2 mt-4 rounded-md bg-[#3FA7D6] text-white font-bold transition duration-200 hover:bg-[#ebdbc4] hover:text-[#374151] border-2 border-transparent hover:border-black"
              onClick={handleStart}
            >
              I am ready to help <br /> Annie and Morgan
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Adventure;
