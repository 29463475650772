import React from 'react'

const NotFound = () => {
  return (
    <div>
      <p>Sorry <span>Please Signout and Login Again</span></p>
    </div>
  )
}

export default NotFound
