import React, { useState, useEffect, useCallback } from "react";
import BodyMap from "../BodyMapNew";
import Correct3 from "./Correct3";
import Incorrect3 from "./Incorrect3";
import axios from "axios";
import headAudio from "../../assets/audios/Head.mp3";
import stomachAudio from "../../assets/audios/Tummy.mp3";
import mouthAudio from "../../assets/audios/Mouth.mp3";
import armAudio from "../../assets/audios/Arm.mp3";
import footAudio from "../../assets/audios/Legs.mp3";

const Quest3Quiz = (props) => {
  const [answer, setAnswer] = useState(null);
  const [audioQueue, setAudioQueue] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);

  const playNextAudio = useCallback(() => {
    if (audioQueue.length === 0) {
      setIsPlaying(false);
      return;
    }

    const nextAudioPath = audioQueue[0];
    const nextAudio = new Audio(nextAudioPath);

    nextAudio.play();
    setAudioQueue((queue) => queue.slice(1));

    nextAudio.onended = () => {
      setIsPlaying(false);
    };
  }, [audioQueue]);

  useEffect(() => {
    if (!isPlaying && audioQueue.length > 0) {
      setIsPlaying(true);
      playNextAudio();
    }
  }, [audioQueue, isPlaying, playNextAudio]);
  const handleImageClick = (audioPath) => {
    setAudioQueue((queue) => [...queue, audioPath]);
  };
  const audioMap = {
    Head: headAudio,
    Stomach: stomachAudio,
    Mouth: mouthAudio,
    "Left Arm": armAudio,
    "Right Arm": armAudio,
    Legs: footAudio,
  };
  const handleAreaClick = async (areaTitle) => {
    console.log("Area Title:", areaTitle);
    const audioFile = audioMap[areaTitle];
    if (audioMap[areaTitle]) {
      handleImageClick(audioMap[areaTitle]);
    }
    let activityValue;
    if (areaTitle === "Head") {
      setAnswer("correct");
      activityValue = "correct";
    } else {
      setAnswer("incorrect");
      activityValue = "incorrect";
    }

    // Hit the API to save mission activity
    try {
      const payload = [
        {
          userId: props.carrier.userId,
          missionId: "M2",
          pageNum: 24,
          activityId: "M2Quest3Quiz",
          activityValue: activityValue,
        },
      ];
      const response = await axios.post(
        "https://api.feelingbetterasd.com/mission/activity/saveMissionActivityDetails",
        payload
      );
      console.log("Response:", response.data);
    } catch (error) {
      console.error("Error saving mission activity:", error);
    }
  };

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Annie
      </h2>
      <div className="flex justify-around text-gray-700 text-base font-gillSans">
        {answer === null ? (
          <ol className=" mt-5">
            <li>
              {props.carrier.firstName} can you tell captain Charlie what body
              part hurts Annie?
            </li>
            <li className="mt-4">
              If you need to play the video again, you can go back.
            </li>
            <li className="mt-4">
              Touch the body map to show which body part hurt Annie
            </li>
          </ol>
        ) : answer === "correct" ? (
          <Correct3 carrier={props.carrier} />
        ) : (
          <Incorrect3 handlePrevious={props.handlePrevious} />
        )}
        <BodyMap onAreaClick={handleAreaClick} />
      </div>
    </div>
  );
};

export default Quest3Quiz;
