import React from "react";
import painScale from "../../assets/pain scale/Pain scale.png";

const LearningToCommunicate = () => {
  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Learning To Communicate
      </h2>
      <div className="text-gray-700 text-base font-gillSans">
        <ol className=" ">
          <li className="mt-4">
            Pointing to different faces helps your mum/dad or teacher know when
            you are hurting.
          </li>
          <li className="mt-4">
            When you show them, it hurts, they can help you feel better.
          </li>
          <li className="mt-4">
            Using a pain scale helps your mum and dad to know if you need to go
            to the doctors and what they can do to help
          </li>
          <img src={painScale} alt="painScale" className=" w-96 ml-56 mt-6" />
        </ol>
      </div>
    </div>
  );
};

export default LearningToCommunicate;
