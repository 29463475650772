import React, { useState } from "react";
import painScale0 from "../../assets/pain scale/0.png";
import painScale2 from "../../assets/pain scale/2.png";
import painScale4 from "../../assets/pain scale/4.png";
import painScale6 from "../../assets/pain scale/6.png";
import painScale8 from "../../assets/pain scale/8.png";
import painScale10 from "../../assets/pain scale/10.png";

const MorganQuiz = () => {
  const [clickedIndex, setClickedIndex] = useState(null);

  const handleImageClick = (index) => {
    if (index >= 2 && index <= 5) {
      setClickedIndex(5); // If correct answer
    } else {
      setClickedIndex(-1); // If incorrect answer
    }
  };

  const painScales = [
    painScale0,
    painScale2,
    painScale4,
    painScale6,
    painScale8,
    painScale10,
  ];

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Matey Morgan
      </h2>
      <div className="text-gray-700 text-base font-gillSans">
        <ol className=" mt-5">
          <li className="mt-1">How is Matey Morgan feeling?</li>
          <li className="mt-1 text-blue-500">
            Select the face below to show how Matey Morgan is feeling
          </li>
          <div className="flex mt-10 justify-around items-center gap-5 max-w-3xl">
            {painScales.map((src, index) => (
              <div className="flex items-center flex-col" key={index}>
                <img
                  src={src}
                  alt={`pain-scale-${index}`}
                  className="hover:scale-110 transform transition duration-300 ease-in-out cursor-pointer"
                  onClick={() => handleImageClick(index)}
                />
              </div>
            ))}
          </div>
          {/* Render components based on clicked index */}
          {clickedIndex === 5 && (
            <div className=" bounceIn text-center mt-8">
              <h4>Correct Answer!</h4>
              <ol>
                <li>Excellent Job</li>
                <li>Keep up the hard work.</li>
              </ol>
            </div>
          )}
          {clickedIndex !== 5 && clickedIndex !== null && (
            <div className=" flex flex-col items-center justify-center text-center">
              <div className=" shake text-center mt-8">
                <h4>Incorrect Answer!</h4>
                <ol>
                  <li>Lets try again!</li>
                </ol>
              </div>
            </div>
          )}
        </ol>
      </div>
    </div>
  );
};

export default MorganQuiz;
