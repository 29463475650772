import React from "react";
import bodyPartsVideo from "../../assets/videos/bodyParts.mp4";
import Webcam from "../Webcam";
const Section2 = () => {
  return (
    <div className=" w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-14">
      <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Learn Your Body Parts
      </h2>
      {/* Need to adjust dimensions of div */}
      <p className="mt-1 text-base text-center font-gillSans">
        In the video you will learn about 5 body parts. Can you show me your
        body parts?
        <br />
        Click on the video when you are ready.
      </p>

      <div className=" flex pt-1 justify-center pr-6 gap-5 lg:pr-8">
        <video width="600" controls>
          <source src={bodyPartsVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default Section2;
