import React from "react";

const WelcomeBack4 = ({ carrier }) => {
  return (
    <div className=" w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Welcome Back!
      </h2>
      <div className="text-gray-700 text-base font-gillSans">
        <p className=" mt-10 lg:text-lg">
          Ahoy, Powder Monkey {carrier?.firstName}, Welcome aboard.
        </p>
        <p className=" mt-10 lg:text-lg">
          You have a very special mission to do this week.
        </p>
        <p className=" mt-10 lg:text-lg">
          Your mission is to:{" "}
          <span className=" text-[#F79D84] underline">
            Learn how to communicate pain using a pain scale
          </span>
        </p>
      </div>
    </div>
  );
};

export default WelcomeBack4;
