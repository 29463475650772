import React, { useState } from "react";
import BodyMap from "../BodyMapNew";

const BonusQuestion = () => {
  const [answer, setAnswer] = useState(null);
  const [feedback, setFeedback] = useState("");

  const handleAreaClick = (areaTitle) => {
    if (areaTitle === "Legs") {
      setAnswer("correct");
      setFeedback("Correct Answer! Excellent Job, Keep up the hard work.");
    } else {
      setAnswer("incorrect");
      setFeedback("");
    }
  };

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Bonus Question
      </h2>
      <div className="flex justify-evenly text-gray-700 text-base font-gillSans">
        {answer === null ? (
          <ol className="mt-5">
            <li>What body part hurts Annie?</li>
            <li className="text-blue-500">
              Select the body part on the body map-
            </li>
          </ol>
        ) : answer === "correct" ? (
          <div className=" bounceIn text-center mt-8">
            <h4 className="text-3xl underline">Correct Answer!</h4>
            <ol className="mt-5">
              <li>Excellent Job</li>
              <li>Keep up the hard work.</li>
              <li>You have received a bonus star!</li>
            </ol>
          </div>
        ) : (
          <div className=" shake text-center mt-8">
            <h4 className="text-3xl underline">Incorrect Answer!</h4>
            <ol className="mt-5">
              <li>Let's Try Again.</li>
              <li>
                Remember if you need help, <br /> Click the help button at the
                top of the page.
              </li>
            </ol>
          </div>
        )}
        <BodyMap onAreaClick={handleAreaClick} />
      </div>
      {feedback && (
        <div className="text-center text-gray-700 text-2xl animate-zoomIn font-gillSans"></div>
      )}
    </div>
  );
};

export default BonusQuestion;
