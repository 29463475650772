import React, { useState } from "react";
import axios from "axios";

const Quiz = ({ carrier }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [feedback, setFeedback] = useState("");
  const [feedbackClass, setFeedbackClass] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    if (option === 2) {
      if (carrier && carrier.firstName) {
        setFeedback(
          `Correct answer, Excellent Job Striker ${carrier.firstName}`
        );
      } else {
        setFeedback("Correct answer, Excellent Job Striker!");
      }
      setFeedbackClass("zoomer");
    } else {
      setFeedback("Incorrect answer, Let's Try Again.");
      setFeedbackClass("shake");
    }
  };

  const handleSubmit = async () => {
    setSubmitted(true);
    let userId = carrier.userId;
    // Hit the API to save quiz activity
    try {
      const payload = [
        {
          userId: userId, // Assuming userId is part of carrier
          missionId: "M1", // Example mission ID
          pageNum: 6, // Example page number
          activityId: "M1PainQuiz", // Example activity ID
          activityValue: JSON.stringify({ selectedOption, feedback }), // Save selected option and feedback
        },
      ];
      const response = await axios.post(
        "https://api.feelingbetterasd.com/mission/activity/saveMissionActivityDetails",
        payload
      );
      console.log("Response:", response.data);
    } catch (error) {
      console.error("Error saving mission activity:", error);
    }
  };

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Activity
      </h2>
      <div className="text-gray-700 text-base font-gillSans">
        <p className="mt-5">Choose the correct option:</p>
        <p className="mt-5 underline lg:text-lg text-center">
          What does Matey Morgan do to show his mum/dad he is in pain?
        </p>
        <ol className="list-decimal flex justify-center flex-col items-center">
          <li
            className="mt-5 cursor-pointer hover:scale-110 hover:text-[#F79D84] transform transition duration-300 ease-in-out"
            onClick={() => handleOptionClick(1)}
          >
            Laugh
          </li>
          <li
            className="mt-5 cursor-pointer hover:scale-110 hover:text-[#F79D84] transform transition duration-300 ease-in-out"
            onClick={() => handleOptionClick(2)}
          >
            Point to the different faces on the pain scale.
          </li>
          <li
            className="mt-5 cursor-pointer hover:scale-110 hover:text-[#F79D84] transform transition duration-300 ease-in-out"
            onClick={() => handleOptionClick(3)}
          >
            Run away
          </li>
          {selectedOption && (
            <p className={`mt-10 text-center lg:text-lg ${feedbackClass}`}>
              {feedback}
            </p>
          )}
          {selectedOption && !submitted && (
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
              onClick={handleSubmit}
            >
              Submit
            </button>
          )}
          {submitted && (
            <div className="mt-5 text-center text-green-600 font-bold">Submitted successfully!</div>
          )}
        </ol>
      </div>
    </div>
  );
};

export default Quiz;
