import React, { useState, useEffect } from "react";
import stomachPain from "../../assets/pain scale/Point to stomach.png";
import bodyImage from "../../assets/pain scale/Point to map.png";
import iPad from "../../assets/iPad.png";
import runningAway from "../../assets/Characters/runningAway.png";
import painScale from "../../assets/pain scale/Pain scale.png";
import axios from "axios";
import coin from "../../assets/Icons/Coin.png";
import coins from "../../assets/audios/coins.mp3";

const FinalQuiz = ({ carrier }) => {
  const [selectedIndices, setSelectedIndices] = useState([]);
  const [result, setResult] = useState(null);

  const audioPlay = () => {
    const audio = new Audio(coins);
    audio.play();
  };

  useEffect(() => {
    if (result === "correct") {
      handleSubmit();
      audioPlay();
      sessionStorage.setItem("pQ3Completed", "true");
    }
  }, [result, carrier]);

  const handleImageClick = (index) => {
    setSelectedIndices((prev) => {
      const newSelection = prev.includes(index)
        ? prev.filter((i) => i !== index)
        : [...prev, index].slice(0, 3);

      if (newSelection.length === 3) {
        const correctIndices = [2, 3, 4];
        const isCorrect = correctIndices.every((val) =>
          newSelection.includes(val)
        );
        setResult(isCorrect ? "correct" : "incorrect");

        if (!isCorrect) {
          setTimeout(() => {
            setSelectedIndices([]);
            setResult(null);
          }, 2500);
        }
      }

      return newSelection;
    });
  };

  const handleSubmit = async () => {
    try {
      const payload = [
        {
          userId: carrier.userId,
          missionId: "M3",
          pageNum: 32,
          activityId: "M3Quiz",
          activityValue: JSON.stringify({
            question: "Quiz",
            result: "correct",
          }),
        },
      ];
      const response = await axios.post(
        "https://api.feelingbetterasd.com/mission/activity/saveMissionActivityDetails",
        payload
      );
      console.log("Response:", response.data);
    } catch (error) {
      console.error("Error saving mission activity:", error);
    }
  };

  const placeholders = [
    { src: runningAway, label: "Running away", widthClass: "w-44" },
    { src: iPad, label: "Playing on my iPad", widthClass: "w-36 mt-3" },
    { src: bodyImage, label: "Pointing to body map", widthClass: "w-32" },
    {
      src: stomachPain,
      label: "Pointing to the body part that hurts",
      widthClass: "w-32",
    },
    {
      src: painScale,
      label: "Using a pain scale",
      widthClass: "w-48 mb-12 mt-6",
    },
  ];

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-10">
      <div className="flex flex-row items-center justify-center gap-2">
        <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
          Pirate Quiz
        </h2>
        <img src={coin} className="w-12 ml-20 heartbeat" alt="coin" />
      </div>
      <div className="text-gray-700 text-base font-gillSans">
        <ol className="">
          <li className="mt-1">
            Captain Charlie wants to know the different ways you can communicate
            pain?
          </li>
          <li className="mt-1">
            Select the different ways to communicate pain?
          </li>
          <div className="flex mt-5 justify-around items-center gap-5 max-w-3xl">
            {placeholders.map((placeholder, index) => (
              <div className="flex items-center flex-col" key={index}>
                <img
                  src={placeholder.src}
                  alt={`pain-scale-${index}`}
                  className={`hover:scale-110 transform transition duration-300 ease-in-out cursor-pointer max-h-32 ${
                    placeholder.widthClass
                  } ${
                    selectedIndices.includes(index)
                      ? "border-4 border-[#9c6334] rounded"
                      : ""
                  }`}
                  onClick={() => handleImageClick(index)}
                />
                <span className="text-center mt-5">{placeholder.label}</span>
              </div>
            ))}
          </div>
          {/* Render components based on the result */}
          {result === "correct" && (
            <div className=" bounceIn text-center mt-8">
              <h2 className="text-green-600 text-2xl">Correct Answer!</h2>
              <ol>
                <li>Excellent Job {carrier.firstName}</li>
                <li>Keep up the hard work.</li>
              </ol>
            </div>
          )}
          {result === "incorrect" && (
            <div className=" flex flex-col items-center justify-center text-center">
              <div className=" shake text-center mt-8">
                <h4 className="text-red-600 text-2xl">Incorrect Answer!</h4>
                <ol>
                  <li>Lets try again!</li>
                </ol>
              </div>
            </div>
          )}
        </ol>
      </div>
    </div>
  );
};

export default FinalQuiz;
