import React from "react";
import badge from "../../assets/Badges/mapExpert.png";
import pointToMap from "../../assets/pain scale/Point to map.png";
import coin from "../../assets/Icons/Coin.png";
import coins from "../../assets/audios/coins.mp3";

const MapExpert = () => {
  const audioPlay = () => {
    const audio = new Audio(coins);
    audio.play();
  };
  audioPlay();

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <div className="flex flex-row items-center justify-center gap-2">
        <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
          Using Your Body Map
        </h2>
        <img src={coin} className="w-12 ml-20 heartbeat" alt="coin" />
      </div>
      <div className="text-gray-700 text-base fadeIn font-gillSans">
        <ol>
          <li className="mt-2">
          In Mission 2 you received a very special badge, 'Body Map Expert'.
          </li>
          <li className="mt-2">
            You know that you can point to the body part on the body map to show
            where it hurts.
          </li>
        </ol>
        <ol className="mt-5 flex flex-row justify-around flex-wrap">
          <li className="flex items-center justify-evenly mt-6 fadeIn">
            <img src={badge} className="w-44 " alt="bodyMap" />
          </li>
          <li className="flex flex-col items-center justify-evenly mt-6 fadeIn">
            <img src={pointToMap} className="w-44 " alt="stomachPain" />
            <span className=" text-center">
              Annie's arm hurts. <br /> She is pointing to arm on the body map.
            </span>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default MapExpert;
