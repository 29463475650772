import React from "react";
import SeeYou from "../SeeYou";

const SeeYou4 = () => {
  const rank = "Navigator";
  return (
    <>
      <SeeYou rank={rank} />
    </>
  );
};

export default SeeYou4;
