import React, { useRef } from "react";
import noPain from "../../assets/pain scale/0.png";
import painScale0 from "../../assets/audios/painScale0.mp3";
import coin from "../../assets/Icons/Coin.png";
import coins from "../../assets/audios/coins.mp3";

const NoPain = () => {
  const audioRef = useRef(null);

  const audioPlay = () => {
    const audio = new Audio(coins);
    audio.play();
  };
  audioPlay();

  const handlePlayClick = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <div className="flex flex-row items-center justify-center gap-2">
        <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
          No Pain
        </h2>
        <img src={coin} className="w-12 ml-20 heartbeat" alt="coin" />
      </div>
      <div className="text-gray-700 text-base flex flex-row font-gillSans">
        <div className="flex flex-col">
          <p className="mt-10 lg:text-lg">
            This face shows that you are happy and that no body part hurts.
          </p>
          <p className="mt-10 lg:text-lg">
            Press play to hear that there is no pain.
          </p>
          <button
            onClick={handlePlayClick}
            className="px-8 py-2 mt-10 rounded-md flex-row w-32 bg-green-600 text-white font-bold transition duration-200 hover:bg-green-500 border-2 border-transparent"
          >
            Play
          </button>
        </div>
        <div className="mt-12">
          <img src={noPain} alt="cptCharlie" className="w-56" />
        </div>
      </div>
      <audio ref={audioRef} src={painScale0} />
    </div>
  );
};

export default NoPain;
