import React from "react";
import Captain from "../../assets/cptCharlie.png";
import bodyImage from "../../assets/bodyImageResized.png";
import child from "../../assets/Characters/child.png";
import greenTick from "../../assets/tick.png";

const BodyPartSectionDone = () => {
  const isChildCompleted = true; // Adjust this based on your completion logic
  const isBodyImageCompleted = false; // Adjust this based on your completion logic

  return (
    <>
      <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-20">
        <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
          Knowing your body parts
        </h2>
        <div className="flex justify-evenly items-center">
          <ol className="text-gray-700 text-lg lg:mt-2 font-gillSans">
            <li className="lg:mt-4">Sometimes our body parts can hurt.</li>
            <li className="lg:mt-4">
              When a body part hurts it is important to show your mum/dad or
              teacher where the body part hurts.
            </li>
            <li className="lg:mt-4">
              Showing the body part that hurts to your mum/dad can help you feel
              better. You can show them by:
              <div className="flex flex-row lg:w-56 ml-24 justify-evenly items-center">
                {/* Child Image with Tick */}
                <div className="max-lg:hidden">
                  <div className="relative">
                    <img src={child} alt="child" className="lg:w-44 md:w-14" />
                    {isChildCompleted && (
                      <img
                        src={greenTick}
                        alt="completed"
                        className="absolute top-0 right-0 w-full h-full"
                      />
                    )}
                  </div>
                  <span className="text-center text-base">
                    Touching your body part.
                  </span>
                </div>

                <div className="max-lg:hidden">
                  <p className="text-center lg:text-base">OR</p>
                </div>

                {/* Body Image with Tick */}
                <div className="max-lg:hidden">
                  <div className="relative">
                    <img
                      src={bodyImage}
                      alt="bodyImage"
                      className="lg:w-14 md:w-6 ml-5"
                    />
                    {isBodyImageCompleted && (
                      <img
                        src={greenTick}
                        alt="completed"
                        className="absolute top-0 right-0 w-full h-full"
                      />
                    )}
                  </div>
                  <span className="text-center text-base">
                    Touching the body map.
                  </span>
                </div>
              </div>
              <ol className="list-decimal mt-2 ml-5 lg:hidden">
                <li>Touching your body part.</li>
                <li className="mt-2">Touching the body map.</li>
              </ol>
            </li>
          </ol>
          <img src={Captain} className="lg:w-64 md:w-40" alt="" />
        </div>
      </div>
    </>
  );
};

export default BodyPartSectionDone;
