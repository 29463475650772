import React from 'react'

const mission12 = () => {
  return (
    <div>
      <p>mission12</p>
    </div>
  )
}

export default mission12
