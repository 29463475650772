import React, { useEffect } from "react";
import coin from "../../assets/Icons/Coin.png";
import coins from "../../assets/audios/coins.mp3";

const Correct2 = ({carrier}) => {
  const audioPlay = () => {
    // Play celebration audio when the component mounts
    const audio = new Audio(coins);
    audio.play();
  };

  useEffect(() => {
    const audio = new Audio(coins);
    // audio.play();

    // Clean up the audio when the component unmounts
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, []);

  audioPlay();
  sessionStorage.setItem("quest2Completed", "true");

  return (
    <div className=" flex max-w-lg flex-col bounceIn">
      <div className="flex flex-row items-center justify-center gap-2">
        <h4 className=" text-xl mt-5 text-center text-green-600">
          Correct Answer!
        </h4>
        <img src={coin} className="w-12 ml-20 heartbeat" alt="coin" />
      </div>
      <ol className="  mt-5">
        <li>Excellent Job Deckhand {carrier?.firstName}</li>
        <li className=" mt-2">Keep up the hard work.</li>
        <li className=" mt-2">
          You have 1 more task to help Captain Charlie make his friends feel
          better.
        </li>
        <li className=" mt-2">Let's go hear another story from Annie.</li>
      </ol>
    </div>
  );
};

export default Correct2;
