import React, { useState, useEffect } from "react";
import axios from "axios";
import BodyMap from "../BodyMapNew";
import painScale0 from "../../assets/pain scale/0.png";
import painScale2 from "../../assets/pain scale/2.png";
import painScale4 from "../../assets/pain scale/4.png";
import painScale6 from "../../assets/pain scale/6.png";
import painScale8 from "../../assets/pain scale/8.png";
import painScale10 from "../../assets/pain scale/10.png";
import coin from "../../assets/Icons/Coin.png";
import coins from "../../assets/audios/coins.mp3";
import footBall from "../../assets/videos/Football.mp4";

const Activity2Question = ({ carrier }) => {
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [firstAnswer, setFirstAnswer] = useState(null);
  const [secondAnswer, setSecondAnswer] = useState(null);
  const [feedback, setFeedback] = useState("");
  const [clickedIndex, setClickedIndex] = useState(null);

  const audioPlay = () => {
    const audio = new Audio(coins);
    audio.play();
  };

  // Handle feedback and result submission for first question
  useEffect(() => {
    if (
      firstAnswer === "correct" &&
      feedback ===
        "Correct Answer! Excellent Job " +
          carrier.pirateRank +
          " " +
          carrier.firstName +
          " Keep up the hard work."
    ) {
      handleSubmit();
    }
  }, [firstAnswer, feedback, carrier]);

  const handleAreaClick = (areaTitle) => {
    if (areaTitle === "Head") {
      setFirstAnswer("correct");
      setFeedback(
        "Correct Answer! Excellent Job " +
          carrier.pirateRank +
          " " +
          carrier.firstName +
          " Keep up the hard work."
      );
      setCurrentQuestion(2); // Move to the next question
    } else {
      setFirstAnswer("incorrect");
      setFeedback("Incorrect. Please try again.");
    }
  };

  const handleImageClick = (index) => {
    if (index >= 2 && index <= 5) {
      setSecondAnswer("correct");
      setClickedIndex(5);
    } else {
      setSecondAnswer("incorrect");
      setClickedIndex(-1);
    }
  };

  const handleSubmit = async () => {
    try {
      const payload = [
        {
          userId: carrier.userId,
          missionId: "M3",
          pageNum: 32,
          activityId: "M3Annie's_Pain_Special_Quiz",
          activityValue: JSON.stringify({
            question: "Annie's Pain Special Quiz",
            result:
              firstAnswer === "correct" && secondAnswer === "correct"
                ? "correct"
                : "incorrect",
          }),
        },
      ];
      const response = await axios.post(
        "https://api.feelingbetterasd.com/mission/activity/saveMissionActivityDetails",
        payload
      );
      console.log("Response:", response.data);
    } catch (error) {
      console.error("Error saving mission activity:", error);
    }
  };

  const handleTryAgain = () => {
    // Reset the quiz to the first question
    setCurrentQuestion(1);
    setFirstAnswer(null);
    setSecondAnswer(null);
    setFeedback("");
    setClickedIndex(null);
  };

  if (firstAnswer === "correct" && secondAnswer === "correct") {
    audioPlay();
    sessionStorage.setItem("activity2Completed", "true");
  }

  const painScales = [
    painScale0,
    painScale2,
    painScale4,
    painScale6,
    painScale8,
    painScale10,
  ];

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-20">
      <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        {carrier.firstName}'s Task
      </h2>

      {/* Display Incorrect Component if any answer is incorrect */}
      {firstAnswer === "incorrect" || secondAnswer === "incorrect" ? (
        <div className="flex max-w-base flex-col shake font-gillSans">
          <h2 className="text-xl mt-5 text-center text-red-600">
            Incorrect Answer!
          </h2>
          <ol className="mt-3 text-center text-base">
            <li>Let's Try Again.</li>
            <li className="mt-3">
              Let's watch the video again and find out what is pain.
            </li>
            <li className="mt-3">
              Remember if you need help, click the help button at the top of the
              page.
            </li>
            <li>
              <div className="flex pt-5 justify-center pr-6 gap-5 lg:pr-8">
                <video width="250" controls>
                  <source src={footBall} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </li>
          </ol>
          <div className="flex justify-center">
            <button
              className="px-4 py-2 mt-4 w-32 text-base bg-red-800 text-white rounded-md hover:bg-red-600"
              onClick={handleTryAgain}
            >
              Try Again
            </button>
          </div>
        </div>
      ) : (
        // Display the Quiz or Correct Feedback based on the answers
        <>
          {firstAnswer === "correct" && secondAnswer === "correct" ? (
            <div className="text-center text-gray-700 text-2xl animate-zoomIn font-gillSans">
              <div className="flex max-w-base flex-col bounceIn">
                <div className="flex flex-row items-center justify-center gap-2">
                  <h2 className="text-3xl mt-5 text-center text-green-600">
                    Correct Answer!
                  </h2>
                  <img src={coin} className="w-12 ml-20 heartbeat" alt="coin" />
                </div>
                <ol className="mt-5">
                  <li>Excellent Job Striker {carrier?.firstName}</li>
                  <li className="mt-2">Keep up the hard work.</li>
                </ol>
              </div>
            </div>
          ) : (
            <>
              {currentQuestion === 1 && firstAnswer === null && (
                <div className="flex justify-evenly text-gray-700 text-lg font-gillSans fadeIn">
                  <ol className="mt-5">
                    <li>Can you select the body part that hurts Annie?</li>
                  </ol>
                  <BodyMap onAreaClick={handleAreaClick} />
                </div>
              )}

              {currentQuestion === 2 &&
                firstAnswer === "correct" &&
                secondAnswer === null && (
                  <div className="text-gray-700 text-lg text-center font-gillSans fadeIn">
                    <ol className="mt-5">
                      <li className="mt-1">How was Annie feeling?</li>
                      <div className="flex mt-10 justify-around items-center gap-5 max-w-3xl">
                        {painScales.map((src, index) => (
                          <div
                            className="flex items-center flex-col"
                            key={index}
                          >
                            <img
                              src={src}
                              alt={`pain-scale-${index}`}
                              className="hover:scale-110 transform transition duration-300 ease-in-out cursor-pointer"
                              onClick={() => handleImageClick(index)}
                            />
                          </div>
                        ))}
                      </div>
                    </ol>
                  </div>
                )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Activity2Question;
