import React from "react";
import cptCharlie from "../../../assets/cptCharlie.png";

const GetStarted = () => {
  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-10">
      <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Let's get started!
      </h2>
      <div className="text-gray-700 text-base font-gillSans mx-5">
        <div className="flex items-center justify-center mt-5">
          <img src={cptCharlie} alt="Captain Charlie" className=" w-96" />
        </div>
      </div>
    </div>
  );
};

export default GetStarted;
