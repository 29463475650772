import React from 'react'

const mission7 = () => {
  return (
    <div>
    <h1>Mission 7</h1>
    </div>
  )
}

export default mission7
