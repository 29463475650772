import React from "react";
import cptCharlie from "../../../assets/cptCharlie.png";
import mateyMorgan from "../../../assets/Characters/MateyMorgan.png";
import polly from "../../../assets/Characters/SailorPolly.png";
import annie from "../../../assets/Characters/Annie.png";
import amy from "../../../assets/Characters/SailorAmy.jpg";
import riley from "../../../assets/Characters/Riley.png";

const Crew = () => {
  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-20">
      <style>
        {`
          .crew-image {
            width: 155px; 
            height: 155px; 
            object-fit: cover; 
          }
        `}
      </style>
      <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Meet Captain Charlie and his Crew
      </h2>
      <div className="text-gray-700 text-base font-gillSans">
        <p>This is Captain Charlie:</p>
        <div className="flex items-center flex-col">
          <img
            src={cptCharlie}
            alt="charlie"
            className="hover:scale-110 transform transition duration-300 ease-in-out w-32"
          />
        </div>
        <p>Here is the Crew: </p>
        <div className="flex mt-1 justify-between pr-6 gap-5 lg:pr-8">
          <div className="flex items-center flex-col">
            <img
              src={annie}
              alt="annie"
              className="crew-image hover:scale-110 transform transition duration-300 ease-in-out "
            />
            <span className="mt-2 font-gillSans">Annie</span>
          </div>
          <div className="flex items-center flex-col">
            <img
              src={mateyMorgan}
              alt="morgan"
              className="crew-image hover:scale-110 transform transition duration-300 ease-in-out "
            />
            <span className="mt-2 font-gillSans">Matey Morgan</span>
          </div>
          <div className="flex items-center flex-col">
            <img
              src={polly}
              alt="polly"
              className="crew-image hover:scale-110 transform transition duration-300 ease-in-out "
            />
            <span className="mt-2 font-gillSans">Sailor Polly</span>
          </div>
          <div className="flex items-center flex-col">
            <img
              src={amy}
              alt="amy"
              className="crew-image hover:scale-110 transform transition duration-300 ease-in-out "
            />
            <span className="mt-2 font-gillSans">Sailor Amy</span>
          </div>
          <div className="flex items-center flex-col">
            <img
              src={riley}
              alt="riley"
              className="crew-image hover:scale-110 transform transition duration-300 ease-in-out "
            />
            <span className="mt-2 font-gillSans">Doctor Riley</span>
          </div>
        </div>
        <p className="text-gray-700 text-base font-gillSans text-center mt-4">
          You will help Captain Charlie and his Crew each week.
        </p>
        <p className="text-gray-700 text-base font-gillSans text-center">
          You are a key member of this Crew!
        </p>
      </div>
    </div>
  );
};

export default Crew;
