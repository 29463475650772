import React from "react";
import chest from "../../assets/miscellaneous/chest.png";

const WelcomeBack2 = ({ onNext, carrier }) => {
  const handleNext = () => {
    onNext();
  };

  return (
    <div className=" w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Welcome Back!
      </h2>
      <div className="text-gray-700 text-base font-gillSans">
        <ol>
          <li className=" mt-4 lg:text-lg">
            Ahoy, Deckhand {carrier?.firstName}, Welcome aboard
          </li>
          <li className=" mt-4 lg:text-lg">
            You have a very special mission to do this week.
          </li>
          <li className=" mt-4 lg:text-lg">
            Click the treasure chest to find out your mission.
          </li>
          <li className=" mt-4 ml-10 floater cursor-pointer">
            <img
              src={chest}
              onClick={handleNext}
              className="w-32"
              alt="chest"
            />
          </li>
        </ol>
      </div>
    </div>
  );
};

export default WelcomeBack2;
