import React from "react";
import footBall from "../../assets/videos/Communicating Pain.mp4";
import coin from "../../assets/Icons/Coin.png";
import coins from "../../assets/audios/coins.mp3";

const CommunicatingPain = () => {
  const audioPlay = () => {
    const audio = new Audio(coins);
    audio.play();
  };

  audioPlay();

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-20">
      <div className="flex flex-row items-center justify-center gap-2">
        <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
          Communicating Pain
        </h2>
        <img src={coin} className="w-12 ml-20 heartbeat" alt="coin" />
      </div>
      <div className="text-gray-700 text-base font-gillSans">
        <ol className="mt-4">
          <li className="mt-1">
            It is important that when you are in pain that you tell your mum/dad
            or teacher.
          </li>
          <li className="mt-1">Telling them can help you feel better.</li>
          <li className="mt-1">Play the video below to learn more</li>
          <div className="  flex mt-5 justify-center mr-6 gap-5 lg:pr-8">
            <video width="450" controls>
              <source src={footBall} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </ol>
      </div>
    </div>
  );
};

export default CommunicatingPain;
