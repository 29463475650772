import React from "react";
import UnderWater from "../assets/UnderWater.jpg";
import Board from "../assets/Boards/blankBoard.png";
import continueBoard from "../assets/Boards/continueBoard.png";
import startBoard from "../assets/Boards/startBoard.png";
import { useNavigate } from "react-router-dom";
import "../assets/wickedcss.min.css";
import NavBar from "./NavBar";
import { useLocation } from "react-router-dom";
import painDiaryBoard from "../assets/Boards/painDiaryBoard.png";
const HomePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const carrier = location.state?.carrier;
  console.log(carrier)

  function handleMission() {
    navigate("/map", { state: { carrier } });
  }
  function painDiaryHandler() {
    navigate("/pain-diary", { state: { carrier } });
  }
  return (
    // BACKGROUND IMAGE
    <>
      <div
        className="h-screen relative flex flex-col items-center justify-center"
        style={{
          backgroundImage: `url(${UnderWater})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundColor: "#2cdee2",
        }}
      >
        <div className="absolute top-0 left-0 w-full">
          <NavBar carrier = {carrier} />
        </div>

        <div className="flex m-[15%] top-16 absolute w-[50rem] slideDown">
          <div
            className="flex flex-col justify-center relative hover:transform hover:scale-110 transition-transform duration-300 cursor-pointer"
            onClick={handleMission}
          >
            <img src={continueBoard} alt="continue" />
          </div>

          <div
            className="flex flex-col justify-center relative hover:transform hover:scale-110 transition-transform duration-300 cursor-pointer"
            onClick={handleMission}
          >
            <img src={startBoard} alt="start" />
          </div>

          <div
            className="flex flex-col justify-center relative hover:transform hover:scale-110 transition-transform duration-300 cursor-pointer"
            onClick={painDiaryHandler}
          >
            <img src={painDiaryBoard} alt="pain-diary" />
          </div>
        </div>
        <div className="text-center relative top-80 fadeIn">
          <span>© 2024 | University of Galway, Ireland</span>
        </div>
      </div>
    </>
  );
};

export default HomePage;
