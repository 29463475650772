import React, { useState } from "react";
import painScale0 from "../../assets/pain scale/0.png";
import painScale8 from "../../assets/pain scale/8.png";
import polly from "../../assets/Characters/SailorPolly.png";

const Polly = () => {
  const [clickedIndex, setClickedIndex] = useState(null);

  const handleImageClick = async (index) => {
    if (index === 0) {
      setClickedIndex(0);
    } else {
      setClickedIndex(1);
    }
  };

  const painScales = [painScale0, painScale8];

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Sailor Polly's Pain
      </h2>
      <div className="text-gray-700 text-base font-gillSans">
        <ol className="">
          <li className="mt-1">
            Have a listen to Polly taking about a time she was not in pain and a
            time she was in pain.
          </li>
          <li className="mt-4">
            Click onto the{" "}
            <span className=" text-green-600 font-bold"> green</span> face to
            hear when she was happy, and click onto the{" "}
            <span className=" text-orange-600 font-bold">orange</span> face to
            hear when she had pain-
          </li>
          <div className="flex mt-10 justify-evenly items-center gap-5 max-w-m">
            {painScales.map((src, index) => (
              <div className="flex items-center flex-col" key={index}>
                <img
                  src={src}
                  alt={`pain-scale-${index}`}
                  className="hover:scale-110 transform transition duration-300 ease-in-out cursor-pointer w-14"
                  onClick={() => handleImageClick(index)}
                />
              </div>
            ))}
          </div>
          {/* Render components based on clicked index */}
          {clickedIndex === 0 && (
            <div className=" fadeIn text-center mt-4">
              <ol>
                <div className=" flex flex-row justify-around">
                  <div>
                    <img src={polly} alt="polly" className=" w-40" />
                  </div>
                  <div>
                    <h2 className="text-xl font-bold mb-2 text-green-600">No pain!</h2>
                    <ul>
                      <li className="mt-2">
                        I went to the park one day with my dad. We were playing
                        on the swings. I was really happy.
                      </li>
                      <li className="mt-2">
                        Just like the happy face on the pain scale.
                      </li>
                      <li className="mt-2">
                        This means I don't feel any pain at all.
                      </li>
                    </ul>
                  </div>
                </div>
              </ol>
            </div>
          )}
          {clickedIndex !== 0 && clickedIndex !== null && (
            <div className=" fadeIn text-center mt-4">
              <div className=" flex flex-row justify-around">
                <div>
                  <img src={polly} alt="polly" className=" w-40" />
                </div>
                <div>
                  <h2 className="text-xl font-bold mb-2 text-orange-600">
                    A whole lot of pain!
                  </h2>
                  <ul>
                    <li className="mt-2">
                      One time I had a really bad stomach pain and all I wanted
                      to do was lie on my bed.
                    </li>
                    <li className="mt-2">
                      I was very sad just like the orange face on the pain
                      scale.
                    </li>
                    <li className="mt-2">
                      This face is for when something hurts a lot.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
        </ol>
      </div>
    </div>
  );
};

export default Polly;
