import React from 'react'

const mission9 = () => {
  return (
    <div>
      <p>mission9</p>
    </div>
  )
}

export default mission9
