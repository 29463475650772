import React from "react";

const Mission1Intro = () => {
  return (
    <div className="w-80 h-72 flex justify-center items-center text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7">
      <h2
        className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-4xl md:mt-2 lg:text-5xl font-gillSans"
      >
        Mission 1
      </h2>
    </div>
  );
};

export default Mission1Intro;
