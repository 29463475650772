import React, { useState } from "react";
import BodyMap from "../BodyMapNew";
import axios from "axios";

const PainThisWeek = ({ carrier }) => {
  const [clickedAreas, setClickedAreas] = useState([]);
  const [submitted, setSubmitted] = useState(false);

  // Function to update clicked areas
  const handleAreaClick = (area) => {
    // Check if the clicked area already exists in clickedAreas
    if (clickedAreas.includes(area)) {
      setClickedAreas((prevClickedAreas) =>
        prevClickedAreas.filter((clickedArea) => clickedArea !== area)
      );
    } else {
      setClickedAreas((prevClickedAreas) => [...prevClickedAreas, area]);
    }
  };

  const handleSubmit = async () => {
    setSubmitted(true);
    let userId = carrier.userId;
    // Hit the API to save mission activity
    try {
      const payload = [
        {
          userId: userId, // Assuming userId is passed as a prop
          missionId: "M1", // Example mission ID
          pageNum: 6, // Example page number
          activityId: "M1BodyMap", // Example activity ID
          activityValue: JSON.stringify(clickedAreas), // Convert clickedAreas to a JSON string
        },
      ];
      const response = await axios.post(
        "https://api.feelingbetterasd.com/mission/activity/saveMissionActivityDetails",
        payload
      );
      console.log("Response:", response.data);
    } catch (error) {
      console.error("Error saving mission activity:", error);
    }
  };

  // Determine the number of columns based on the length of clickedAreas
  const numColumns = clickedAreas.length > 10 ? 2 : 1;

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Using Your Body Map
      </h2>
      <div className="text-gray-700 text-base flex justify-evenly font-gillSans">
        <ol className="mt-5">
          <li className="mt-5">How was your pain this week.</li>
          <li className="mt-5">
            Click on the body parts that hurt you this week.
          </li>
          {/* Render the clicked areas as a list */}
          {submitted && <p className="mt-5">You submitted:</p>}
          <div className="grid grid-cols-2 gap-4">
            {[...Array(numColumns)].map((_, colIndex) => (
              <ol
                key={colIndex}
                start={
                  colIndex * Math.ceil(clickedAreas.length / numColumns) + 1
                }
                className="list-decimal mt-2"
              >
                {clickedAreas
                  .slice(
                    colIndex * Math.ceil(clickedAreas.length / numColumns),
                    (colIndex + 1) * Math.ceil(clickedAreas.length / numColumns)
                  )
                  .map((area, index) => (
                    <li key={index}>{area}</li>
                  ))}
              </ol>
            ))}
          </div>
          {!submitted && (
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
              onClick={handleSubmit}
            >
              Submit
            </button>
          )}
          {submitted && <div className="mt-5">Submitted successfully!</div>}
        </ol>
        <BodyMap onAreaClick={handleAreaClick} />
      </div>
    </div>
  );
};

export default PainThisWeek;
