import React from 'react'

const mission5 = () => {
  return (
    <div>
    <h1>Mission 5</h1>
    </div>
  )
}

export default mission5
