import React from "react";
import painScale0 from "../../assets/pain scale/0.png";
import painScale2 from "../../assets/pain scale/2.png";
import painScale4 from "../../assets/pain scale/4.png";
import painScale6 from "../../assets/pain scale/6.png";
import painScale8 from "../../assets/pain scale/8.png";
import painScale10 from "../../assets/pain scale/10.png";

const PainScale = () => {
  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Using a Pain Scale
      </h2>
      <div className="text-gray-700 text-base font-gillSans">
        <ol className=" ">
          <li className="mt-1">
            When you are in pain, you can use a pictures of faces (pain scale)
            to tell your mum/dad or teacher you are in pain.
          </li>
          <li className="mt-1">Each faces shows how much it hurts.</li>
          <li className="mt-1">Have a listen to each face to hear pain.</li>
          <div className="  flex mt-10 justify-around items-center fadeIn gap-5 max-w-3xl">
            <div className="flex items-center flex-col">
              <img
                src={painScale0}
                alt="pain-scale"
                className="hover:scale-110 transform transition duration-300 ease-in-out cursor-pointer"
              />
              <span className="mt-2 text-center font-gillSans">
                0 <br />
                No hurt
              </span>
            </div>
            <div className="flex items-center flex-col">
              <img
                src={painScale2}
                alt="pain-scale"
                className="hover:scale-110 transform transition duration-300 ease-in-out cursor-pointer"
              />
              <span className="mt-2 text-center font-gillSans">
                2 <br />
                Hurts little bit
              </span>
            </div>
            <div className="flex items-center flex-col">
              <img
                src={painScale4}
                alt="pain-scale"
                className="hover:scale-110 transform transition duration-300 ease-in-out cursor-pointer"
              />
              <span className="mt-2 text-center font-gillSans">
                4 <br />
                Hurts little more
              </span>
            </div>
            <div className="flex items-center flex-col">
              <img
                src={painScale6}
                alt="pain-scale"
                className="hover:scale-110 transform transition duration-300 ease-in-out cursor-pointer"
              />
              <span className="mt-2 text-center font-gillSans">
                6 <br />
                Hurts even more
              </span>
            </div>
            <div className="flex items-center flex-col">
              <img
                src={painScale8}
                alt="pain-scale"
                className="hover:scale-110 transform transition duration-300 ease-in-out cursor-pointer"
              />
              <span className="mt-2 text-center font-gillSans">
                8 <br />
                Hurts whole lot
              </span>
            </div>
            <div className="flex items-center flex-col">
              <img
                src={painScale10}
                alt="pain-scale"
                className="hover:scale-110 transform transition duration-300 ease-in-out cursor-pointer"
              />
              <span className="mt-2 text-center font-gillSans">
                10 <br />
                Hurts worst
              </span>
            </div>
          </div>
        </ol>
      </div>
    </div>
  );
};

export default PainScale;
