import React from "react";
import painScale from "../../assets/pain scale/Pain scale.png";
import mateyMorgan from "../../assets/Characters/MateyMorgan.png";

const MorganQuestion = () => {
  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Matey Morgan
      </h2>
      <div className="text-gray-700 text-base font-gillSans">
        <ol className=" mr-10">
          <li className="mt-4">
            Hey there! Do you like playing outside? Me too! Sometimes, when I am
            having lots of fun running around,{" "}
            <span className=" text-green-600 font-bold">I might fall.</span>{" "}
          </li>
          <li className="mt-4">
            This can{" "}
            <span className=" text-green-600 font-bold"> hurt my arm </span>
            for a short time. I sometimes
            <span className=" text-green-600 font-bold"> cry</span> a little
            because
            <span className=" text-green-600 font-bold">my leg hurts.</span> My
            pain is like the{" "}
            <span className=" text-green-600 font-bold">
              orange face on the pain scale.
            </span>
          </li>
          <div className="flex flex-row justify-around">
            <img src={painScale} alt="painScale" className=" w-96 h-36 mt-6" />
            <img src={mateyMorgan} alt="mateyMorgan" className=" w-56  mt-6" />
          </div>
        </ol>
      </div>
    </div>
  );
};

export default MorganQuestion;
