import React from "react";
import missionMap from "../assets/missionMap.png";

const FirstThenBoard = ({ inputValue, onInputChange }) => {
  // Function to handle input change
  const handleInputChange = (event) => {
    onInputChange(event.target.value);
  };

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        First-Then Board
      </h2>
      <div
        className="text-gray-700 text-base"
        style={{ fontFamily: "montserrat" }}
      >
        <div className="flex flex-row justify-evenly items-center mt-14 mr-4">
        <style>
      {`
        .arrow-container {
          background-color: #59CD90;
          width: 60px;
          height: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 30px;
          cursor: pointer;
        }

        .arrow-svg {
          width: 30px;
          height: 30px;
          color: #ffffff; /* Or any color you prefer for the arrow */
        }
      `}
    </style>
          {/* Missions Map */}
          <div>
            <img
              src={missionMap}
              alt="missionMap"
              className="lg:w-80 md:w-48 rounded border-black"
            />
            <p className="text-center text-lg uppercase font-gillSans">First</p>
          </div>
          {/* Arrow */}        
          <svg
            className="w-10 h-10"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>          
          {/* TextBox */}
          <div>
            <textarea
              value={inputValue}
              onChange={handleInputChange}
              className="border border-black p-2 rounded lg:h-64 lg:w-80 md:w-44 md:h-40"
              placeholder="(Type in what you want to do after programme e.g. iPad, toy etc. )"
              style={{ resize: "none" }}
            />
            <p className="text-center text-lg uppercase font-gillSans">Then</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstThenBoard;
