import React, { useRef } from "react";
import mateyMorgan from "../../assets/Characters/MateyMorgan.png";
import mateyMorganAudio from "../../assets/audios/mateyMorganAudio.mp3";

const MateyMorgan = () => {
  const audioRef = useRef(null);

  const handlePlayClick = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Matey Morgan
      </h2>
      <div className="text-gray-700 text-base flex flex-row font-gillSans">
        <div className="flex flex-row gap-10">
          <div className="flex flex-col">
            <p className="mt-10 lg:text-lg">
              Have a listen to Sailor Morgan talk about the importance <br /> of
              using a pain scale
            </p>
            <button
              onClick={handlePlayClick}
              className="px-8 py-2 mt-10 rounded-md flex-row w-32 bg-green-600 text-white font-bold transition duration-200 hover:bg-green-500 border-2 border-transparent"
            >
              Play
            </button>
          </div>
          <div className="mt-12">
            <img src={mateyMorgan} alt="cptCharlie" className="w-72" />
          </div>
        </div>
      </div>
      <audio ref={audioRef} src={mateyMorganAudio} />
    </div>
  );
};

export default MateyMorgan;
