import React from "react";
import Captain from "../../../assets/cptCharlie.png";

const Intro = () => {
  return (
    <>
      <div className=" relative w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
        <div className=" flex flex-row items-center justify-evenly mt-10">
          <img src={Captain} className=" w-72" alt="captain" />
          <h2 className="text-xl font-bold text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-5xl font-gillSans">
            Pain Diary
          </h2>
        </div>
      </div>
    </>
  );
};

export default Intro;
