import React from "react";
import cptCharlie from "../../../assets/cptCharlie.png";
import speaker from "../../../assets/speaker.png";

const ReadAloud = () => {
  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Listen to Captain Charlie
      </h2>
      <div className="text-gray-700 flex flex-row text-base font-gillSans mx-5">
        <div>
          <p className="mt-5 lg:text-lg">
            If you would like to hear what Captain Charlie is talking about you
            can press this button.
          </p>
          <p className="mt-5 lg:text-lg">
            <img
              src={speaker}
              alt="speaker"
              className="hover:scale-110 ease-in-out transform transition duration-300"
            />
          </p>
          <p className="mt-5 lg:text-lg">
            Captain Charlie will then read out the words on the screen.
          </p>
        </div>
        <div>
          <img src={cptCharlie} alt="Captain Charlie" className=" w-3/5" />
        </div>
      </div>
    </div>
  );
};

export default ReadAloud;
