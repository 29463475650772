import React from "react";
import MissionComplete from "../MissionComplete";

const Mission2Complete = ({ carrier }) => {
  const completedMissionId = 2;
  const rank = "Striker";
  const coins = 15;
  const userId = carrier.userId;
  const currentMissionId = 3;
  const currentMissionPage = 31;
  let newStars = 15;
  let prevStars = carrier.totalStars;
  return (
    <>
      <MissionComplete
        completedMissionId={completedMissionId}
        rank={rank}
        userId={userId}
        coins={coins}
        currentMissionId={currentMissionId}
        currentMissionPage={currentMissionPage}
        newStars={newStars}
        prevStars={prevStars}
      />
    </>
  );
};

export default Mission2Complete;
