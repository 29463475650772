import React from "react";

const WhatIsPain = () => {
  return (
    <div className=" w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        What is Pain?
      </h2>
      <div className="text-base mx-5 font-gillSans">
        <ul className="font-gillSans">
          <li className=" mt-8 ">
            Pain tends to be an unpleasant feeling that can range from mild
            discomfort to chronic pain that impacts on the person's quality of
            life.
          </li>
          <li className=" mt-8 ">
            Pain is always a personal experience that is influenced to varying
            degrees by biological, psychological and social factors.
          </li>
          <li className=" mt-8 ">
            Most people experience acute pain or short-term pain such as a
            headache, which will usually go away after a few hours or pain from
            an injury which will normally resolve within a few weeks.
          </li>
          <li className=" mt-8 ">
            While most pain is acute (pain goes away after a short period), many
            people may suffer from chronic pain.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default WhatIsPain;
