import React, { useState, useEffect } from "react";

const BreakTimer = ({ onClose }) => {
  const [timeLeft, setTimeLeft] = useState(300);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    let interval = null;

    if (isActive && timeLeft > 0) {
      interval = setInterval(() => {
        setTimeLeft((timeLeft) => timeLeft - 1);
      }, 1000);
    } else if (!isActive && timeLeft !== 0 && timeLeft < 300) {
      clearInterval(interval);
    }

    if (timeLeft === 0) {
      setIsActive(false);
      setTimeLeft(300); // Reset timer automatically
      alert("Break time is over!");
      onClose(); // Notify parent component when timer ends
    }

    return () => clearInterval(interval);
  }, [isActive, timeLeft, onClose]);

  const handleStart = () => {
    setIsActive(true);
  };

  const handleCancel = () => {
    onClose();
  };

  // Calculate the percentage of the timer
  const percentage = (timeLeft / 300) * 100;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      {/* Backdrop */}
      <div className="absolute inset-0 bg-black opacity-50"></div>

      {/* Timer and buttons */}
      <div className="relative z-10 flex flex-col items-center justify-center h-48 bg-transparent p-4 ">
        <div
          className="w-36 h-36 rounded-full flex items-center justify-center shadow-md mb-5"
          style={{
            background: `conic-gradient(#4caf50 ${
              percentage * 3.6
            }deg, #e0e0e0 0deg)`,
          }}
        >
          <div className="font-semibold text-lg font-gillSans text-grey-700">
            {isActive
              ? `${Math.floor(timeLeft / 60)}:${timeLeft % 60 < 10 ? "0" : ""}${
                  timeLeft % 60
                }`
              : "5:00"}
          </div>
        </div>
        <div className="flex space-x-4">
          <button
            className="px-10 py-1 bg-[#4caf50] border-none text-grey-700 font-semibold rounded-md"
            onClick={handleStart}
          >
            Start
          </button>
          <button
            className="px-10 py-1 bg-[#f44336] border-none text-grey-700 font-semibold rounded-md"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default BreakTimer;
