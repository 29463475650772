import React, { useState } from "react";
import painScale0 from "../../assets/pain scale/0.png";
import painScale2 from "../../assets/pain scale/2.png";
import painScale4 from "../../assets/pain scale/4.png";
import painScale6 from "../../assets/pain scale/6.png";
import painScale8 from "../../assets/pain scale/8.png";
import painScale10 from "../../assets/pain scale/10.png";
import videoIcon from "../../assets/Icons/videoIcon.png";
import Level4 from "./Level4";
import Level6 from "./Level6";
import Level10 from "./Level10";

const PainLevels = ({ carrier }) => {
  const [selectedPart, setSelectedPart] = useState(null);
  const [completedParts, setCompletedParts] = useState({
    4: sessionStorage.getItem("completed_4") === "true",
    6: sessionStorage.getItem("completed_6") === "true",
    10: sessionStorage.getItem("completed_10") === "true",
  });

  const handleMouseClick = (part) => {
    setSelectedPart(part);
  };

  const handleQuizSuccess = (part) => {
    console.log("Quiz completed for part:", part);
    const updatedParts = { ...completedParts, [part]: true };
    setCompletedParts(updatedParts);
    sessionStorage.setItem(`completed_${part}`, "true");
    setSelectedPart(null);
    if (updatedParts["4"] && updatedParts["6"] && updatedParts["10"]) {
      sessionStorage.setItem("painLevelsCompleted", "true");
    }
  };

  const renderComponent = () => {
    switch (selectedPart) {
      case "4":
        return (
          <Level4
            onQuizSuccess={() => handleQuizSuccess("4")}
            carrier={carrier}
          />
        );
      case "6":
        return (
          <Level6
            onQuizSuccess={() => handleQuizSuccess("6")}
            carrier={carrier}
          />
        );
      case "10":
        return (
          <Level10
            onQuizSuccess={() => handleQuizSuccess("10")}
            carrier={carrier}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      {selectedPart ? (
        renderComponent()
      ) : (
        <>
          <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
            Pain Scale
          </h2>
          <div className="text-gray-700 text-base font-gillSans">
            <ol>
              <li className="mt-4">
                Click on the 3 Faces to learn more about pain.
              </li>
            </ol>
            <div className="flex mt-10 justify-around items-center fadeIn gap-5 max-w-3xl">
              {/* Face 0 */}
              <div className="flex items-center flex-col mb-8">
                <img
                  src={painScale0}
                  alt="pain-scale"
                  className="hover:scale-110 transform transition duration-300 ease-in-out cursor-pointer"
                />
                <span className="mt-2 text-center font-gillSans">
                  0 <br />
                  No hurt
                </span>
              </div>
              {/* Face 2 */}
              <div className="flex items-center flex-col mb-8">
                <img
                  src={painScale2}
                  alt="pain-scale"
                  className="hover:scale-110 transform transition duration-300 ease-in-out cursor-pointer"
                />
                <span className="mt-2 text-center font-gillSans">
                  2 <br />
                  Hurts little bit
                </span>
              </div>
              {/* Face 4 */}
              <div className="flex items-center flex-col">
                <img
                  src={painScale4}
                  alt="pain-scale"
                  className={`hover:scale-110 transform transition duration-300 ease-in-out cursor-pointer ${
                    completedParts["4"] ? "grayscale" : ""
                  }`}
                  onClick={() => handleMouseClick("4")}
                />
                <span className="mt-2 text-center font-gillSans">
                  4 <br />
                  Hurts little more
                </span>
                <img src={videoIcon} className="w-8" alt="video" />
              </div>
              {/* Face 6 */}
              <div className="flex items-center flex-col">
                <img
                  src={painScale6}
                  alt="pain-scale"
                  className={`hover:scale-110 transform transition duration-300 ease-in-out cursor-pointer ${
                    completedParts["6"] ? "grayscale" : ""
                  }`}
                  onClick={() => handleMouseClick("6")}
                />
                <span className="mt-2 text-center font-gillSans">
                  6 <br />
                  Hurts even more
                </span>
                <img src={videoIcon} className="w-8" alt="video" />
              </div>
              {/* Face 8 */}
              <div className="flex items-center flex-col mb-8">
                <img
                  src={painScale8}
                  alt="pain-scale"
                  className="hover:scale-110 transform transition duration-300 ease-in-out cursor-pointer"
                />
                <span className="mt-2 text-center font-gillSans">
                  8 <br />
                  Hurts whole lot
                </span>
              </div>
              {/* Face 10 */}
              <div className="flex items-center flex-col">
                <img
                  src={painScale10}
                  alt="pain-scale"
                  className={`hover:scale-110 transform transition duration-300 ease-in-out cursor-pointer ${
                    completedParts["10"] ? "grayscale" : ""
                  }`}
                  onClick={() => handleMouseClick("10")}
                />
                <span className="mt-2 text-center font-gillSans">
                  10 <br />
                  Hurts worst
                </span>
                <img src={videoIcon} className="w-8" alt="video" />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PainLevels;
