const Incorrect1 = ({ handlePrevious }) => {
  const handleGoBack = () => {
    // Call the handlePrevious function passed as a prop
    handlePrevious();
  };

  return (
    <div className="flex max-w-lg flex-col shake">
      <h4 className="text-xl mt-5 text-center text-red-600">Incorrect Answer!</h4>
      <ol className=" mt-5">
        <li>Let's Try Again.</li>
        <li className="mt-8">
          Let's Watch the video again and tell Captain Charlie what body part
          hurts Annie.
        </li>
        <li className="mt-8">
          Remember if you need help. Click the help button at the top of the
          page.
        </li>
      </ol>
      <button
        className="px-4 py-2 mt-4 w-32 bg-red-800 text-white rounded-md hover:bg-red-600"
        onClick={handleGoBack}
      >
        Go Back
      </button>
    </div>
  );
};

export default Incorrect1;
