import React from "react";
import SeeYou from "../SeeYou";

const SeeYou3 = () => {
  const rank = "Powder Monkey";
  return (
    <>
      <SeeYou rank={rank} />
    </>
  );
};

export default SeeYou3;
