import React from "react";
import Captain from "../../assets/cptCharlie.png";
import confettiGif from "../../assets/videos/Celebrations/confetti.gif";

const Impressive = () => {
  return (
    <>
      <div className=" relative w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
        <h2 className="text-xl font-bold text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
          Excellent Job!
        </h2>
        <div className=" flex justify-evenly items-center">
          <img src={Captain} className=" w-72" alt="" />
          <ol className="text-gray-700 text-base  lg:text-lg font-gillSans">
            <li>Excellent job Powder Monkey!</li>
            <li className=" mt-4">Captain Charlie is very impressed.</li>
            <li className=" mt-4">
              You are doing a super job remembering how to use the pain scale.
            </li>
          </ol>
        </div>
        <img
          src={confettiGif}
          alt="confetti"
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none"
          style={{ width: "60%", height: "auto" }}
        />
      </div>
    </>
  );
};

export default Impressive;
