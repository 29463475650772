import React from "react";

const ImpactCP = () => {
  return (
    <div className=" w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        The impact of Chronic Pain
      </h2>
      <div className="text-base mt-8 font-gillSans">
        <p className="mt-3">Pain can impact on:</p>
        <ul className="ml-5">
          <li className=" mt-3 ">Daily functioning</li>
          <li className=" mt-3 ">Sleep</li>
          <li className=" mt-3 ">Emotional well-being</li>
          <li className=" mt-3 ">Quality of life</li>
        </ul>
        <p className="mt-3">
          Over time chronic pain can result in increased feelings of sadness,
          anxiety, and psychological distress.
        </p>
        <p className="mt-3">
          It is important that children and their parents or carers are
          supported to practice positive coping strategies.
        </p>
      </div>
    </div>
  );
};

export default ImpactCP;
