import React from "react";
import anniePain from "../../assets/Characters/annieInPain.png";
import coin from "../../assets/Icons/Coin.png";
import coins from "../../assets/audios/coins.mp3";

const WhatIsPain = () => {
  const audioPlay = () => {
    const audio = new Audio(coins);
    audio.play();
  };
  audioPlay();

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <div className="flex flex-row items-center justify-center gap-2">
        <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
          What is Pain
        </h2>
        <img src={coin} className="w-12 ml-20 heartbeat" alt="coin" />
      </div>
      <div className="text-gray-700 text-base justify-evenly flex flex-row font-gillSans">
        <div>
          <ol className=" ">
            <li className="mt-5">
              Everyone experiences pain from time to time.
            </li>
            <li className="mt-5">
              Pain is important as it how your body tells you something is
              wrong.
            </li>
            <li className="mt-5">
              It is important to tell your mum/dad or teacher if you are feeling
              any pain.
            </li>
          </ol>
        </div>
        <div>
          <img src={anniePain} alt="Annie feeling pain" className=" w-48" />
        </div>
      </div>
    </div>
  );
};

export default WhatIsPain;
