import React from "react";
import starIcon from "../assets/star.png"; // Make sure to replace this with the actual path to your star icon

const ProgressBar = ({ currentSection, totalSections }) => {
  const progress = (currentSection / totalSections) * 100;

  return (
    <div className="relative lg:w-[55rem] md:w-[34rem] bg-[#8b5e3c] h-6 rounded-full overflow-hidden">
      <div
        className="bg-[#76c7c0] h-full transition-width duration-500 ease-in-out"
        style={{ width: `${progress}%` }}
      ></div>
      {/* Star Icons */}
      <div className="absolute top-0 left-0 h-full flex items-center justify-between w-full px-2">
        {[...Array(totalSections)].map((_, index) => (
          <img
            key={index}
            src={starIcon}
            alt="star"
            className={`w-6 h-6 ${
              index + 1 <= currentSection ? "opacity-100" : "opacity-50"
            }`}
            style={{ marginLeft: index === 0 ? "0" : "-12px" }} // Adjust spacing between stars
          />
        ))}
      </div>
    </div>
  );
};

export default ProgressBar;
