import React from "react";
import SeeYou from "../SeeYou";

const SeeYou2 = () => {
  const rank = "Striker";
  return (
    <>
      <SeeYou rank={rank} />
    </>
  );
};

export default SeeYou2;
