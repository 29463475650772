import React from "react";
import bodyMap from "../../assets//bodyImageResized.png";

const BodyMapSectionIntro = () => {
  return (
    <>
      <div className=" w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
        <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
          Using the Body Map
        </h2>
        <ol className="text-gray-700 text-lg  mt-2 font-gillSans">
          <li>
            When a body part hurt's we can show someone where it hurts using a
            body map.
          </li>
        </ol>
        <div className=" flex justify-around items-center text-gray-700 text-base font-gillSans">
          <ol className=" mt-5 ">
            <li>This is the body map</li>
          </ol>
          <img src={bodyMap} alt="bodyMap" className=" w-32 invert" />
        </div>
      </div>
    </>
  );
};

export default BodyMapSectionIntro;
