import React from "react";
import bikeFall from "../../assets/videos/Falling off a bike.mp4";

const MorganPain = () => {
  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Matey Morgan
      </h2>
      <div className="text-gray-700 text-base font-gillSans">
        <ol className="lg:mt-1 ">
          <li className="lg:mt-6">
            Next you will listen to Morgan talk about his pain.
          </li>
          <li className="lg:mt-6">
            After the listening to Morgan, you must help him pick the face to
            show how much pain he has.
          </li>
          <div className="  flex lg:mt-5 md:mt-2 justify-center pr-6 gap-5 lg:pr-8">
            <video className="lg:w-96 md:w-80" controls>
              <source src={bikeFall} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </ol>
      </div>
    </div>
  );
};

export default MorganPain;
