import React from "react";
import SeeYou from "../SeeYou";

const SeeYou1 = ({carrier}) => {
  const rank = carrier.pirateRank;
  return (
    <>
      <SeeYou rank={rank} carrier={carrier}/>
    </>
  );
};

export default SeeYou1;
