import React from 'react'

const mission10 = () => {
  return (
    <div>
      <p>mission10</p>
    </div>
  )
}

export default mission10
