import React, { useState } from "react";

const PirateQuiz = ({ onNext }) => {
  const [ready, setReady] = useState(false);

  const handleStart = () => {
    setReady(true);
    onNext();
  };

  return (
    <div className=" w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        A Pirates Adventure
      </h2>
      <div className="text-center">
        <ul>
          <li className=" mt-10 lg:text-lg md:text-lg">
            Powder Monkey (name of child) your pirate task is to answer 2
            important questions.
          </li>
          <li className=" mt-10 lg:text-lg md:text-lg">
            Captain Charlie wants to test your knowledge on what you learned
            today.
          </li>
          <li className=" mt-10 lg:text-lg md:text-lg">
            You will receive your special pirate surprise after you finished the
            pirate
          </li>
        </ul>
        {ready ? (
          <p>Started!</p>
        ) : (
          <button
            className="px-8 py-2 mt-4 rounded-md bg-[#3FA7D6] text-white font-bold transition duration-200 hover:bg-[#ebdbc4] hover:text-[#374151] border-2 border-transparent hover:border-black"
            onClick={handleStart}
          >
            Yes, Let's Go!
          </button>
        )}
      </div>
    </div>
  );
};

export default PirateQuiz;
