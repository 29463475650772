import React from "react";
import cptCharlie from "../../../assets/cptCharlie.png";

const MissionToYou = () => {
  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Captain Charlie's Mission to You
      </h2>
      <div className="text-gray-700 flex flex-row text-base font-gillSans mx-5">
        <div>
          <p className="mt-5 lg:text-lg">
            Captain Charlie wants you to learn about pain and he and his crew
            will help you along the way.
          </p>
          <p className="mt-5 lg:text-lg">
            Learning about pain is a key skill in becoming a captain.
          </p>
          <p className="mt-5 lg:text-lg">Let's begin learning about pain.</p>
          <p className="mt-5 lg:text-lg">
            Are you ready to help Captain Charlie and his Crew?
          </p>
          <p className="mt-5 lg:text-lg">Let's go join the team!</p>
        </div>
        <div>
          <img src={cptCharlie} alt="Captain Charlie" className=" w-4/5" />
        </div>
      </div>
    </div>
  );
};

export default MissionToYou;
