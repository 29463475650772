import React, { useState } from "react";
import axios from "axios";
import painScale0 from "../../assets/pain scale/0.png";
import painScale2 from "../../assets/pain scale/2.png";
import painScale4 from "../../assets/pain scale/4.png";
import painScale6 from "../../assets/pain scale/6.png";
import painScale8 from "../../assets/pain scale/8.png";
import painScale10 from "../../assets/pain scale/10.png";

const YourTurn = ({ carrier }) => {
  const [clickedIndex, setClickedIndex] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  const handleImageClick = async (index) => {
    if (index >= 0 && index <= 5) {
      setClickedIndex(index);
    } else {
      console.error(
        "Index out of range. Please provide an index between 0 and 5."
      );
      return;
    }

    let userId = carrier.userId;
    // Hit the API to save activity details
    try {
      const payload = [
        {
          userId: userId, // Assuming userId is part of carrier
          missionId: "M3", // Example mission ID
          pageNum: 26, // Example page number
          activityId: "M3PainQuiz", // Example activity ID
          activityValue: JSON.stringify({ selectedOption: index }), // Save selected option
        },
      ];
      const response = await axios.post(
        "https://api.feelingbetterasd.com/mission/activity/saveMissionActivityDetails",
        payload
      );
      console.log("Response:", response.data);
      setSubmitted(true);
    } catch (error) {
      console.error("Error saving mission activity:", error);
    }
  };

  const painScales = [
    painScale0,
    painScale2,
    painScale4,
    painScale6,
    painScale8,
    painScale10,
  ];

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Your Turn
      </h2>
      <div className="text-gray-700 text-base font-gillSans">
        <ol className="">
          <li className="mt-1">
            Captain Charlie wants to know{" "}
            <span className=" font-semibold"> how are you feeling?</span>
          </li>
          <li className="mt-4">
            Remember if you are in pain or something is hurting pick the face
            that shows pain.
          </li>
          <div className="flex mt-10 justify-around items-center gap-5 max-w-3xl">
            {painScales.map((src, index) => (
              <div className="flex items-center flex-col" key={index}>
                <img
                  src={src}
                  alt={`pain-scale-${index}`}
                  className="hover:scale-110 transform transition duration-300 ease-in-out cursor-pointer"
                  onClick={() => handleImageClick(index)}
                />
              </div>
            ))}
          </div>
          {/* Render components based on clicked index */}
          {clickedIndex !== null && (
            <div className=" text-center mt-4 font-extrabold text-lg">
              <ol>
                <li>
                  {clickedIndex === 0 && "No Pain!"}
                  {clickedIndex === 1 && "Little Pain"}
                  {clickedIndex === 2 && "Moderate Pain"}
                  {clickedIndex === 3 && "Severe Pain"}
                  {clickedIndex === 4 && "Very Severe Pain"}
                  {clickedIndex === 5 && "Worst Possible Pain"}
                </li>
              </ol>
            </div>
          )}
        </ol>
        {submitted && (
          <div className="mt-5 text-center">Submitted successfully!</div>
        )}
      </div>
    </div>
  );
};

export default YourTurn;
