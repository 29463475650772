import React from "react";
import cptCharlie from "../../assets/cptCharlie.png";

const WelcomeBack = ({ onNext, carrier }) => {
  const handleNext = () => {
    onNext();
  };

  return (
    <div className=" w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Welcome Aboard!
      </h2>
      <div className="flex flex-row">
        <div className="text-gray-700 text-base font-gillSans">
          <p className=" mt-10 lg:text-lg">
            Ahoy, {carrier?.firstName} , Welcome aboard.
          </p>
          <p className=" mt-10 lg:text-lg">
            You have joined Captain Charlie and his crew.
            <br />
            You have a very special mission to complete. 

          </p>
          <p className=" mt-10 lg:text-lg">
            Click here to find out what your mission is:{" "}
            <span
              className="underline cursor-pointer text-blue-600"
              onClick={handleNext}
            >
              My Mission
            </span>
          </p>
        </div>
        <div className="ml-20">
          <img src={cptCharlie} alt="cptCharlie" className=" w-72" />
        </div>
      </div>
    </div>
  );
};

export default WelcomeBack;
