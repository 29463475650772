import React from "react";
import whatIsPain from "../../assets/videos/What is pain.mp4";
import coin from "../../assets/Icons/Coin.png";
import coins from "../../assets/audios/coins.mp3";

const Section1 = () => {
  const audioPlay = () => {
    const audio = new Audio(coins);
    audio.play();
  };
  audioPlay();

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-20">
      <div className="flex flex-row items-center justify-center gap-2">
        <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
          Pain
        </h2>
        <img src={coin} className="w-12 ml-20 heartbeat" alt="coin" />
      </div>
      <div className="text-gray-700 text-base font-gillSans">
        <ol className=" ">
          <li className="mt-1">Sometimes our body parts can hurt.</li>
          <li className="mt-1">This is called pain.</li>
          <li className="mt-1">Click on the video to learn more about pain.</li>
          <div className="  flex pt-5 justify-center pr-6 gap-5 lg:pr-8">
            <video width="450" controls>
              <source src={whatIsPain} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </ol>
      </div>
    </div>
  );
};

export default Section1;
