import React from 'react'

const mission8 = () => {
  return (
    <div>
    <h1>Mission 8</h1>
    </div>
  )
}

export default mission8
