import React, { useState, useEffect } from "react";
import BodyMap from "../../BodyMapNew";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Hurt = () => {
  const [clickedAreas, setClickedAreas] = useState([]);
  const [selectedBodyParts, setSelectedBodyParts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showBodyMap, setShowBodyMap] = useState(false);
  const [noPainMessage, setNoPainMessage] = useState(""); // State to control the "No Pain" message

  // Access location state to get carrier object
  const location = useLocation();
  const carrier = location.state?.carrier;
  const userId = carrier?.userId; // Set the user ID here from carrier

  useEffect(() => {
    // Fetch the pain diary entries from the API
    fetchPainDiary(userId);
  }, [userId]); // Include userId in the dependency array to refetch when it changes

  const fetchPainDiary = async (userId) => {
    try {
      const response = await fetch(
        `https://api.feelingbetterasd.com/painDiary/${userId}`
      );
      const data = await response.json();
      setSelectedBodyParts(data.bodyParts); // Assuming the API response contains an array of body parts
      setLoading(false);
    } catch (error) {
      console.error("Error fetching pain diary:", error);
      setLoading(false);
    }
  };

  const logPainDiary = async () => {
    setLoading(true);

    const payload = {
      userId: userId,
      bodyPart: clickedAreas.join(","),
      diaryEntryTime: new Date().toISOString(),
    };

    try {
      const response = await axios.post(
        "https://api.feelingbetterasd.com/logPainDiary",
        payload
      );
      console.log("Pain diary entry logged successfully:", response.data);
      toast.success("Pain diary entry logged successfully!");
    } catch (error) {
      console.error("Error logging pain diary entry:", error);
      toast.error("Error logging pain diary entry!");
    } finally {
      setLoading(false);
    }
  };

  const logNoPainDiary = async () => {
    setLoading(true);
  
    const payload = {
      userId: userId,
      bodyPart: "No Pain",
      diaryEntryTime: new Date().toISOString(),
    };
  
    try {
      const response = await axios.post(
        "https://api.feelingbetterasd.com/logPainDiary",
        payload
      );
      console.log("Pain diary entry logged successfully:", response.data);
      toast.success("Pain diary entry logged successfully!");
      setNoPainMessage("Great! Click on the arrow to continue"); // Show the message
    } catch (error) {
      console.error("Error logging pain diary entry:", error);
      toast.error("Error logging pain diary entry!");
    } finally {
      setLoading(false);
    }
  };
  

  const handleAreaClick = (area) => {
    if (clickedAreas.includes(area)) {
      setClickedAreas((prevClickedAreas) =>
        prevClickedAreas.filter((clickedArea) => clickedArea !== area)
      );
    } else {
      setClickedAreas((prevClickedAreas) => [...prevClickedAreas, area]);
    }
  };

  const numColumns = clickedAreas.length > 10 ? 2 : 1;

  return (
    <div className="w-full flex justify-center items-center -mt-32">
      {!showBodyMap ? (
        <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
          <div className="flex flex-row items-center justify-center gap-2">
            <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-xl md:mt-2 lg:text-5xl font-gillSans">
              Body Parts that hurt
            </h2>
          </div>
          <div className="text-gray-700 text-base font-gillSans">
            <p className="lg:mt-10 text-center lg:text-lg md:mr-1">
              Did you have any body parts that hurt today?
            </p>
            <div className="flex flex-row space-x-5 items-center justify-center lg:mt-8 md:mt-4">
              <button
                className="px-12 py-4 rounded-full bg-green-500 font-bold text-white tracking-widest uppercase transform hover:scale-105 hover:bg-green-700 transition-colors duration-200"
                onClick={() => setShowBodyMap(true)}
              >
                Yes
              </button>
              <button
                className="px-12 py-4 rounded-full bg-red-500 font-bold text-white tracking-widest uppercase transform hover:scale-105 hover:bg-red-700 transition-colors duration-200"
                onClick={logNoPainDiary}
              >
                No
              </button>
            </div>
            {/* Display the message when "No" is clicked */}
            {noPainMessage && (
              <p className="text-green-600 font-bold text-center mt-4">
                {noPainMessage}
              </p>
            )}
          </div>
        </div>
      ) : (
        <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-24">
          <div className="flex flex-row items-center justify-center gap-2">
            <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-xl md:mt-2 lg:text-5xl font-gillSans">
              My Body Map
            </h2>
          </div>
          <div className="text-gray-700 text-base font-gillSans">
            <p className="lg:mt-2 text-center lg:text-lg md:mr-1">
              What body parts hurt today?
            </p>
            <div className="text-gray-700 text-base flex justify-evenly font-gillSans">
              <ol className="mt-5">
                <li className="mt-5">
                  Click on the body parts that hurt you this week.
                </li>
                <p className="mt-5">You selected:</p>
                <div className="grid grid-cols-2 gap-4">
                  {[...Array(numColumns)].map((_, colIndex) => (
                    <ol
                      key={colIndex}
                      start={
                        colIndex * Math.ceil(clickedAreas.length / numColumns) +
                        1
                      }
                      className="list-decimal mt-2"
                    >
                      {clickedAreas
                        .slice(
                          colIndex *
                            Math.ceil(clickedAreas.length / numColumns),
                          (colIndex + 1) *
                            Math.ceil(clickedAreas.length / numColumns)
                        )
                        .map((area, index) => (
                          <li key={index}>{area}</li>
                        ))}
                    </ol>
                  ))}
                </div>
                <button
                  onClick={logPainDiary}
                  className="bg-[#267e8c] p-2 rounded-lg text-white font-gillSans"
                >
                  Log Pain Diary
                </button>
              </ol>
              {loading ? (
                <p>Loading...</p>
              ) : (
                <BodyMap
                  onAreaClick={handleAreaClick}
                  selectedAreas={selectedBodyParts}
                />
              )}
            </div>
          </div>
          <ToastContainer />
        </div>
      )}
    </div>
  );
};

export default Hurt;
