import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import homeButton from "../../assets/homeBtn.png";
import School from "../School";
import Ready from "../Ready";
import sectionsBG from "../../assets/sectionsBG.png";
import ProgressBar from "../ProgressBar";
import FirstThenBoard from "../FirstThenBoard";
import { useLocation } from "react-router-dom";
import helpAudio from "../../assets/audios/INeedHelp.mp3";
import Mission4Intro from "./Mission4Intro";
import MissionPath from "./MissionPath";
import WelcomeBack4 from "./WelcomeBack4";
import MissionThisWeek4 from "./MissionThisWeek4";
import PainThisWeek from "./PainThisWeek";
import MissionPath1 from "./MissionPath1";
import PainScale from "./PainScale";
import QuickFire from "./QuickFire";
import Impressive from "./Impressive";
import MissionPath2 from "./MissionPath2";
import LearningToCommunicate from "./LearningToCommunicate";
import ActivityTime from "./ActivityTime";
import MateyMorgan from "./MateyMorgan";
import MateyMorgan2 from "./MateyMorgan2";
import Quiz from "./Quiz";
import BonusPoints from "./BonusPoints";
import PSAdventure from "./PSAdventure";
import Polly from "./Polly";
import YourTurn from "./YourTurn";
import Adventure from "./Adventure";
import AnniePain from "./AnniePain";
import AnnieQuiz from "./AnnieQuiz";
import BonusQuestion from "./BonusQuestion";
import MorganPain from "./MorganPain";
import MorganQuestion from "./MorganQuestion";
import MorganQuiz from "./MorganQuiz";
import PSConclusion from "./PSConclusion";
import PSConclusion2 from "./PSConclusion2";
import MissionPath3 from "./MissionPath3";
import PirateQuiz from "./PirateQuiz";
import PiratePainQuiz from "./PiratePainQuiz";
import Badge4 from "./Badge4";
import MissionPath4 from "./MissionPath4";
import Mission4Complete from "./Mission4Complete";
import EndMission4 from "./EndMission4";
import MissionPath5 from "./MissionPath5";
import SeeYou4 from "./SeeYou4";
import BreakTimer from "../BreakTImer";

const Mission4 = () => {
  // Initialize the section state using useState
  const [section, setSection] = useState(1);
  const [isBreak, setIsBreak] = useState(false);

  const [inputValue, setInputValue] = useState(
    sessionStorage.getItem("firstThenInput") || ""
  ); // Initialize with session storage value
  // useNavigate init.
  const navigate = useNavigate();

  // Decrease the section value when "Previous" button is clicked
  const handlePrevious = () => {
    setSection(section - 1);
    console.log(section - 1);
  };

  // Determine if the "Next" button should be disabled
  const handleNext = () => {
    setSection(section + 1);
    console.log(section + 1);
  };

  const isPreviousDisabled = section === 1;
  const isNextDisabled = section === 48;
  const handleInputChange = (value) => {
    setInputValue(value);
    sessionStorage.setItem("firstThenInput", value); // Save to session storage
  };
  const totalSections = 43;
  const location = useLocation();
  const carrier = location.state?.carrier;
  // Function to handle home button click
  const handleHomeClick = () => {
    // Navigate back to the previous screen
    navigate(-1);
  };

  // Function to show toast notification
  const handleBreakClick = () => {
    toast.success("You can take a break now, your progress has been saved!");
    setIsBreak(true);
  };

  // Function to play help audio
  const handleHelpClick = () => {
    const audio = new Audio(helpAudio);
    audio.play();
  };

  // Function to handle closing the break timer
  const handleBreakClose = () => {
    setIsBreak(false);
  };

  return (
    <div className="h-screen flex flex-col justify-between pb-4 pr-3 pl-3 pt-1 bg-[#3FA7D6]">
      <ToastContainer />
      {/* Home Button */}
      <div className=" flex flex-row gap-5 items-center -mb-9 z-10">
        <img
          src={homeButton}
          alt="home-btn"
          className=" cursor-pointer z-10 w-9 hover:scale-110 hover:invert"
          onClick={handleHomeClick}
        />
        <button
          className="px-8 py-2 rounded-md bg-[#EE6352] text-white font-bold transition duration-200 hover:bg-[#ebdbc4] hover:text-[#374151] border-2 border-transparent hover:border-[#9c6334]"
          onClick={handleHelpClick}
        >
          Help
        </button>
        <div className=" pl-24">
          <ProgressBar currentSection={section} totalSections={totalSections} />
        </div>
        <button
          className="px-8 py-2 ml-10 rounded-md bg-[#EE6352] text-white font-bold transition duration-200 hover:bg-[#ebdbc4] hover:text-[#374151] border-2 border-transparent hover:border-[#9c6334]"
          onClick={handleBreakClick}
        >
          Break
        </button>
      </div>
      {/* Render ProgressBar component */}

      <div className="sections flex-grow">
        <div
          className="h-full flex items-center justify-center relative zoomer"
          style={{
            backgroundImage: `url(${sectionsBG})`,
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="absolute inset-0 flex flex-col justify-center items-center pt-4 md:pt-0">
            {isBreak ? (
              <BreakTimer onClose={handleBreakClose} />
            ) : (
              console.log("test")
            )}
          </div>
          <div className=" absolute inset-0 flex flex-col justify-center items-center pt-4 md:pt-0">
            {/* Render content based on the current section */}
            {section === 1 && <Mission4Intro />}
            {section === 2 && <MissionPath />}
            {section === 3 && (
              <FirstThenBoard
                inputValue={inputValue}
                onInputChange={handleInputChange}
              />
            )}
            {section === 4 && <WelcomeBack4 carrier={carrier} />}
            {section === 5 && (
              <MissionThisWeek4 onNext={handleNext} carrier={carrier} />
            )}
            {section === 6 && <School carrier={carrier} />}
            {section === 7 && <PainThisWeek carrier={carrier} />}
            {section === 8 && <MissionPath1 />}
            {section === 9 && <Ready onNext={handleNext} />}
            {section === 11 && <PainScale />}
            {section === 12 && <QuickFire carrier={carrier} />}
            {section === 13 && <Impressive />}
            {section === 14 && <MissionPath2 />}
            {section === 15 && <LearningToCommunicate />}
            {section === 16 && <ActivityTime onNext={handleNext} />}
            {section === 17 && <MateyMorgan />}
            {section === 18 && <MateyMorgan2 />}
            {section === 19 && <Quiz carrier={carrier} />}
            {section === 20 && <BonusPoints />}
            {section === 21 && <PSAdventure />}
            {section === 22 && <Polly />}
            {section === 23 && <YourTurn carrier={carrier} />}
            {section === 24 && <Impressive />}
            {section === 25 && <Adventure onNext={handleNext} />}
            {section === 26 && <AnniePain />}
            {section === 27 && <AnnieQuiz />}
            {section === 28 && <BonusQuestion />}
            {section === 29 && <MorganPain />}
            {section === 30 && <MorganQuestion />}
            {section === 31 && <MorganQuiz />}
            {section === 32 && <PSConclusion />}
            {section === 33 && <PSConclusion2 />}
            {section === 34 && <MissionPath3 />}
            {section === 35 && <PirateQuiz onNext={handleNext} />}
            {section === 36 && <PiratePainQuiz carrier={carrier} />}
            {section === 37 && <Badge4 />}
            {section === 38 && <MissionPath4 />}
            {section === 39 && <Mission4Complete />}
            {section === 40 && <EndMission4 />}
            {section === 41 && <MissionPath5 />}
            {section === 42 && <SeeYou4 />}
            {section === 43 && (
              <FirstThenBoard
                inputValue={inputValue}
                onInputChange={handleInputChange}
              />
            )}
          </div>
        </div>
      </div>

      <div className="flex justify-around">
        {/* "Previous" button */}
        <button
          className="px-12 py-4 rounded-full bg-[#9c6334] font-bold text-white tracking-widest uppercase transform hover:scale-110 hover:bg-[#623e20] transition-colors duration-200"
          onClick={handlePrevious}
          disabled={isPreviousDisabled}
          style={{
            backgroundColor: isPreviousDisabled ? "#1e1e1e" : "#3FA7D6",
            color: isPreviousDisabled ? "#00000" : "#fffff",
          }}
        >
          <svg
            class="w-5 h-5 transform rotate-180"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
          <span class="sr-only">Previous</span>
        </button>

        <div className="text-center text-xs text-white mt-6">
          <span>© 2024 | University of Galway, Ireland</span>
        </div>

        {/* "Next" button */}
        <button
          className="px-12 py-4 rounded-full bg-[#9c6334] font-bold text-white tracking-widest uppercase transform hover:scale-110 hover:bg-[#623e20] transition-colors duration-200"
          onClick={handleNext}
          disabled={isNextDisabled}
          style={{
            backgroundColor: isNextDisabled ? "#1e1e1e" : "#3FA7D6",
          }}
        >
          <svg
            class="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
          <span class="sr-only">Next</span>
        </button>
      </div>
    </div>
  );
};

export default Mission4;
