import React from "react";
import cptCharlie from "../../assets/cptCharlie.png";

const EndMission4 = () => {
  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Mission this week
      </h2>
      <div className="text-gray-700 flex flex-row text-lg font-gillSans">
        <div>
          <p className="mt-2 ">
            Captain Charlie has set you a mission for the week.
          </p>
          <p className="mt-2 ">
            Everyday you are asked to fill in your pain diary. To find your pain
            diary go to the "Resources" Folder
          </p>
          <p className="mt-2 ">
            If a body part hurts click on body part picture. If no body part
            hurt click on the red button.
          </p>
          <p className="mt-2 ">
            <span className=" font-bold">Extra Task: </span> If a body part
            hurts pick a face that shows the level of pain.
          </p>
          <p className="mt-2 ">
            At the end of the week you will receive a special badge from Captain
            Charlie for your hard work.
          </p>
        </div>
        <div>
          <img src={cptCharlie} alt="cptCharlie" className=" w-96" />
        </div>
      </div>
    </div>
  );
};

export default EndMission4;
